import React, { useState, useEffect } from "react";
import "./styles.css";
import Header from "./Header";
import { BiChevronDown } from "react-icons/bi";
import logo from '../../../Assets/nav_logo.png'
import { HiMiniChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";

const HomeNavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const links = [
    {
      title: "Нүүр",
      path: "/",
      //   nested: false,
    },
    {
      title: "Бидний тухай",
      path: '',
      nested: [{ title: "Мэндчилгээ", path: "/main/greeting" }],
    },
    {
      title: "Үйлчилгээ",
      path: "",
      nested: [
        { title: "Хог Хаягдал тээвэрлэлт", path: "/main/service1" },

        { title: "Орон сууцны орц цэвэрлэгээ", path: "/main/service2" },

        { title: "Нэгдсэн хогийн цэг", path: "/main/service3" },
      ],
    },
    {
      title: "Ил тод байдал",
      path: "",
      nested: [
        {
          title: "Үйл ажиллагаа",
          path: "/",
          mininested: [
            {
              title: "Гүйцэтгэлийн төлөвлөгөө биелэлт",
              path: "/finance/Гүйцэтгэлийн төлөвлөгөө биелэлт",
            },
            {
              title: "Өргөдөл гомдол шийдвэрлэлт",
              path: "/about/Өргөдөл гомдол шийдвэрлэлт",
            }
          ]
        },
        {
          title: "Хүний нөөц",
          path: "/",
          mininested: [
            {
              title: "Ёс зүйн дэд хороо мөрдөх дүрэм, журам",
              path: "/about/ethics",
            },
            {
              title: "Хүний нөөцийн хөтөлбөр хэрэгжилт",
              path: "/finance/Хүний нөөцийн хөтөлбөр хэрэгжилт",
            },
            {
              title: "Мэдээ тайлан",
              path: "/finance/Мэдээ тайлан",
            },
            {
              title: "Албан хаагчийн ажлын гүйцэтгэлийг үнэлэх журам",
              path: "/finance/Албан хаагчийн ажлын гүйцэтгэлийг үнэлэх журам",
            },
            {
              title: "Албан тушаалын тодорхойлолт",
              path: "/finance/Албан тушаалын тодорхойлолт",
            },
            {
              title: "Ажлын байрны сул орон тоо",
              path: "/about/worksearch",
            },
            {
              title: "Холбоо барих утсаны дугаар",
              path: "/finance/Холбоо барих утсаны дугаар",
            }
          ]
        },
        {
          title: "Төсөв санхүү",
          path: "/",
          mininested: [
            {
              title: "Тухайн жилийн төсөв",
              path: "/finance/Тухайн жилийн төсөв",
            },
            {
              title: "Өмнөх оны төсвийн гүйцэтгэл",
              path: "/finance/Өмнөх оны гүйцэтгэлийн төсөв",
            },
            {
              title: "Төсвийн гүйцэтгэлийн мэдээ",
              path: "/finance/Төсвийн гүйцэтгэлийн мэдээ",
            },
            {
              title: "Төсвийн төсөл, төсвийн хуваарь",
              path: "/finance/Төсвийн төсөл, төсвийн хуваарь",
            },
            {
              title: "Санхүүгийн тайлан",
              path: "/finance/Санхүүгийн тайлан",
            },
            {
              title: "Аудитын дүгнэлт",
              path: "/finance/Аудитын дүгнэлт",
            },
            {
              title: "Хог хаягдал тээвэрлэлтийн гэрээ",
              path: "/finance/Хог хаягдал тээвэрлэлтийн гэрээ",
            }
          ]
        },
        {
          title: "Төлөөлөн удирдах зөвлөл",
          path: "/",
          mininested: [
            {
              title: "Дүрэм журам",
              path: "/finance/Дүрэм журам",
            },
            {
              title: "Тогтоол шийдвэр",
              path: "/finance/Тогтоол шийдвэр",
            }
          ]
        },
        {
          title: "Хяналт шинжилгээ үнэлгээ",
          path: "/about/control"
        }
      ],
    },
    {
      title: "Хууль, эрх зүй",
      path: "",
      nested: [
        { title: "Эрх зүйн акт", path: "https://legalinfo.mn/mn", link: "https://legalinfo.mn/mn" },

        { title: "Хог хаягдлын тухай", path: "https://legalinfo.mn/mn", link: "https://legalinfo.mn/mn" },
      ],
    },
    {
      title: "Шилэн данс",
      path: "https://shilendans.gov.mn/",
      link: "https://shilendans.gov.mn/",
      nested: false,
    },
    {
      title: "Мэдээ мэдээлэл",
      path: "/main/news",
      nested: false,
    },
    {
      title: "Холбоо барих",
      path: "/main/contact",
      nested: false,
    },
  ];

  return (
    <>
      <div style={{ display: !isScrolled ? "" : "none" }}>
        <Header />
      </div>
      <header className="header">
        <div className="title-container">
          <Link to={'/'}>
            <img
              src={logo}
              alt=""
            />
          </Link>
        </div>
        <nav className="nav">
          <ul className="nav-list">
            {links.map((list, index) => {
              return (
                <li className="nav-item" key={index}>
                  <Link to={list.path} className="nav-link">
                    {list.title}
                    {list.nested ? <BiChevronDown /> : null}
                  </Link>
                  {list.nested ? (
                    <div className='nav-all'>
                      <div className="nav-container">
                        {list.nested.map((el, index) => {
                          return (
                            <>
                              {!el.link ?
                                <Link to={el?.path} className="nav-nested" key={index}>
                                  <span>{el.title} {el.mininested ? <HiMiniChevronRight size={15} color="#838383" /> : null}</span>
                                  {el.mininested && <div className="nav-container-second">
                                    {el.mininested.map((el, index) => {
                                      return (
                                        <Link className="nav-nested" style={{ zIndex: '10000' }} key={index} to={el?.path}>
                                          <span>{el.title}</span>
                                          <div className="divider" style={{
                                            width: '22vh',
                                            borderColor: '#ededed',
                                            borderWidth: '0.1px',
                                            marginTop: '10px'
                                          }}></div>
                                        </Link>
                                      );
                                    })}
                                  </div>}
                                </Link> :
                                <a href={el?.link} className="nav-nested" key={index}>
                                  <span>{el.title} {el.mininested ? <HiMiniChevronRight size={15} color="#838383" /> : null}</span>
                                  {el.mininested && <div className="nav-container-second">
                                    {el.mininested.map((el, index) => {
                                      return (
                                        <Link className="nav-nested" style={{ zIndex: '10000' }} key={index} to={el?.path}>
                                          <span>{el.title}</span>
                                          <div className="divider" style={{
                                            width: '22vh',
                                            borderColor: '#ededed',
                                            borderWidth: '0.1px',
                                            marginTop: '10px'
                                          }}></div>
                                        </Link>
                                      );
                                    })}
                                  </div>}
                                </a>}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </nav>
      </header>
    </>
  );
};

export default HomeNavbar;
