import React from 'react'
import styles from './Director.module.css'
import bg from '../../../Assets/director_bg.png'
import { IoIosStar } from "react-icons/io";
import { Link } from 'react-router-dom';

const Director = () => {
    return (
        <div className={styles.container} style={{ backgroundImage: `url(${bg})` }}>
            <div className={styles.img_div}>
                <img src="https://tseverhot2.fununity.net/wp-content/uploads/2024/04/Darga-1-560x560.jpeg" alt="" />
            </div>
            <div className={styles.content}>
                <div className="big-title" style={{ color: '#fff', fontSize: '25px', fontWeight: '500' }}>
                    Баянхонгор аймаг Цэвэр хот ОНӨААТҮГ Дарга
                </div>
                <p className='text' style={{ color: '#fff' }}>
                    Цэвэр хот Орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газар нь Баянхонгор аймгийн нийтийн ахуйн үйлчилгээний
                    газарт  харъяалагдан хог тээвэрлэлтийн  ажлыг гүйцэтгэж байгаад Чандмань компанид хог тээврийн алба болон үйл ажиллагаа
                    явуулж ба...
                </p>
                <div className={styles.profile}>
                    <div className={styles.info}>
                        <img src="https://tseverhot2.fununity.net/wp-content/uploads/2024/04/Darga-1-560x560.jpeg" alt="" />
                        <div className="column" style={{ alignItems: 'start', gap: '10px' }}>
                            <p className={styles.name}>Цагаандоржын Баясгалан</p>
                            <p className={styles.director}>
                                <i>Захирал</i>
                            </p>
                        </div>
                    </div>
                    <div className={styles.stars}>
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                        <IoIosStar />
                    </div>
                </div>
                <div>
                    <Link to={'/main/greeting'} className={styles.button}>Дэлгэрэнгүй унших</Link>
                </div>
            </div>
        </div>
    )
}

export default Director
