import React from 'react';
import './styles.css';
import HowDoYouDo from '../../Components/Home/HowDoYouDo/HowDoYouDo';
import About from '../../Components/Home/About/About';
import YouTubeEmbed from '../../Components/Home/Video/Video';
import Services from '../../Components/Home/Services/Services';
import Ask from '../../Components/Home/Ask/Ask';
import Director from '../../Components/Home/Director/Director';
import HomeNews from '../../Components/Home/HomeNews/HomeNews';
import SliderNews from '../../Components/Home/SliderNews/SliderNews';

function Home({ news }) {
    return (
        <div>
            <HomeNews />
            <SliderNews news={news} />
            <HowDoYouDo />
            <About />
            <YouTubeEmbed embedId={'CjvNwpFIWB8'} />
            <Services />
            <Ask />
            <Director />
        </div>
    );
}

export default Home;