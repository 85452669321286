import React from 'react'
import styles from '../Unit.module.css'
import img1 from '../../../Assets/About/urgudul1.png'
import img2 from '../../../Assets/About/urgudul2.png'
import img3 from '../../../Assets/About/urgudul3.png'
import img4 from '../../../Assets/About/urgudul4.png'
import img5 from '../../../Assets/About/urgudul5.png'

const ApplicationPage = () => {
    return (
        <div className={styles.container}>
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
            <img src={img5} alt="" />
        </div>
    )
}

export default ApplicationPage
