import React from 'react'
import styles from './Rules.module.css'
import img1 from '../../../Assets/About/tuz_1.png'
import img2 from '../../../Assets/About/tuz_2.png'
import img3 from '../../../Assets/About/tuz_3.png'
import img4 from '../../../Assets/About/tuz_4.png'
import city1 from '../../../Assets/About/city_1.png'
import city2 from '../../../Assets/About/city_2.png'
import city3 from '../../../Assets/About/city_3.png'
import city4 from '../../../Assets/About/city_4.png'
import city5 from '../../../Assets/About/city_5.png'
import city6 from '../../../Assets/About/city_6.png'

const RulesPage = () => {
    return (
        <div className={styles.container}>
            <div>
                <div className="title">ТУЗ дүрэм:</div>
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
            </div>
            <div>
                <div className="title">Цэвэр хот дүрэм:</div>
                <img src={city1} alt="" />
                <img src={city2} alt="" />
                <img src={city3} alt="" />
                <img src={city4} alt="" />
                <img src={city5} alt="" />
                <img src={city6} alt="" />
            </div>
        </div>
    )
}

export default RulesPage
