import React, { useState } from 'react';
import MobileNavbar from '../../Components/Navbar/MobileNavbar';

const Test = () => {
   

    return (
        <div>
            <MobileNavbar />
        </div>
    );
};

export default Test;
