import React from 'react'
import NewsCard from '../../News/NewsCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import { Link } from 'react-router-dom';

const SliderNews = ({ news }) => {
    return (
        <div className='pl-[12vw]'>
            <Swiper
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={50}
                slidesPerView={2}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {news.map((e) => (
                    <SwiperSlide>
                        <div className='py-2 my-5 border-2 flex flex-col items-center justify-center rounded-md'>
                            <p className='text-[#142a63] font-bold'>
                                {e.title}
                            </p>
                            <Link to={'/newsdetial/' + e._id} className='self-end mr-5 my-2 py-1 px-2 bg-[#cd0d13] rounded-md text-[#fff]  text-[14px]'>Дэлгэрэнгүй унших</Link>
                        </div>
                    </SwiperSlide>

                ))}
            </Swiper>
        </div>
        // <div className='flex'>
        //     {news.map((e) => (
        //         <NewsCard data={e} />
        //     ))}
        // </div>
    )
}

export default SliderNews
