import React from 'react';
import styles from './Greeting.module.css';
import Darga from '../../Assets/Darga.jpeg';
import Cover from '../../Uttils/Cover/Cover';

const Greeting = () => {
  return (
    <div className={styles.container}>
      <Cover path1={'HOME'} path2={'МЭНДЧИЛГЭЭ'} title={'Мэндчилгээ - Tseverhot2.Fununity.net'} />
      <div className={styles.contents}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2 className='big-title' style={{ fontSize: '30px' }}>
              Даргын мэндчилгээ
            </h2>
            <p className='title' style={{ color: '#152b63', fontSize: '16px' }}>
              Та бүхэндээ энэ өдрийн мэндийг хүргэе.
            </p>
            <div className={styles.content}>
              Цэвэр хот Орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газар нь Баянхонгор аймгийн нийтийн ахуйн үйлчилгээний газарт  харъяалагдан хог тээвэрлэлтийн  ажлыг гүйцэтгэж байгаад Чандмань компанид хог тээврийн алба болон үйл ажиллагаа явуулж байсан.
            </div>
            <div className={styles.content}>
              2010 оны 5 дугаар сард Баянхонгор аймгийн ИТХ-ын шийдвэрээр Хот тохижилт үйлчилгээний компанид харъяалагдан орж, Сумын Иргэдийн төлөөлөгчдийн хурлын 2014 оны 04 дүгээр сарын 28-ний өдрийн 7/6 дугаар тогтоолоор орон нутгийн өмчит хуулийн этгээдийг өөрчлөн байгуулах шийдвэрийн хүрээнд үйл ажиллагаагаараа бие даан салсан.
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.img_div} >
            <img src={Darga} alt='Darga' />
          </div>
        </div>
        <div className={styles.content}>
          Энэ тогтоолын хүрээнд Хот тохижилт ОНӨҮГазрыг үйл ажиллагааны чиглэлээр нь Хот тохижилт ОНӨҮГазар, Хог тээвэрлэлт ОНӨҮГазар болгон өөрчлөн байгуулж баталсан бөгөөд Хот тохижилт нь улсын төсөвтэй, Хог тээвэрлэлт нь улсын төсөвгүй өөрийн орлого бүрдүүлэлтээр аж ахуйн тооцоон дээрээ үйл ажиллагаагаа бие даан явуулахаар болж 30 орон тоотой, 9 техник хэрэгсэлтэйгээр, жилдээ 126,641,849 мянган төгрөгийн орлоготой, 112,707,637 зардалтай ажиллаж эхэлсэн түүхтэй.
        </div>
        <div className={styles.content}>
          хөдөлмөр, хичээл зүтгэлээс гадна хонгор нутгийн маань иргэд оршин суугчид, харилцагч төрийн болон хувийн хэвшлийн байгууллагуудын эзэн захирлуудын хамтын ажиллагааны үр дүн юм.Цаашид үйл ажиллагаагаа тасралтгүй хөгжүүлэн байгаль орчинд ээлтэй ногоон хөгжлийг дэмжсэн, хог хаягдлыг ангилан ялгаж эдийн засгийн эргэлтэд оруулсан, боловсон хүчнийхээ хөгжил дэвшил, нийгмийн баталгаанд анхаарсан байгууллага баг хамт олон болж хөгжин дэвших болно.
        </div>
        <div className={styles.content}>
          Түүнээс  хойш  Баянхонгор аймгийн ИТХ-ын Тэргүүлэгчдийн 2016 оны 09 дүгээр сарын 28-ны өдрийн 85 дугаар тогтоолоор  “ЦЭВЭР ХОТ” ОНӨ ХХКомпани болж байгууллагын дүрэм журмаа баталж, бүтэц зохион байгуулалтаа өөрчлөн аймгийн хэмжээнд хог хаягдал тээвэрлэлт, орон сууцны цэвэрлэгээ үйлчилгээ, нохой муурын устгалын ажлыг хариуцан хийж өнөөг хүртэл 10 дахь жилдээ тогтвортой үйл ажиллагаагаа явуулж,  амжилттай ажиллаж ирсэн. Манай байгууллага нь хөдөлмөрийн  хүнд нөхцөлд өглөөний мандах нарнаас үдшийн бүрий хүртэл хонгор нутгийн иргэддээ ая тухтай,  хоггүй цэвэр орчинг бүрдүүлэхийн төлөө ажиллан, хариуцсан ажилдаа эзэн болж ажлын амжилт гаргаж яваа нь сэтгэл, зүтгэлтэй, эрч хүчтэй, ур чадвартай баг хамт олны  маань нөр их шаргуу
        </div>
        <div className={styles.content}>
          Бид өглөө бүр, өдөр бүр орчин тойрныхоо хог хаягдал, цэвэр орчинд анхаарч иргэд оршин суугчиддаа хоггүй цэвэр тав тухтай орчинг бүрдүүлсэн эвсэг, найрсаг үйлчилгээг манай хамт олон та бүхэнд үзүүлж  чадна гэдэгт  итгэл төгс байна
          <div className={styles.comments}>
            <p style={{ fontWeight: 550 }}>
              <i>Сэтгэлтэй хүн дээр гэрэл илүү тусдаг</i>
            </p>
            <p style={{ fontWeight: 550 }}>
              <i>Зүтгэлтэй хүнийг амжилт үргэлж дагадаг </i>
            </p>
            <p>
              <i>Хонгор нутгийн зон олон та бүхэндээ ажлын өндөр амжилтыг хүсэн ерөөе.</i>
            </p>
            <p className='title' style={{ color: '#152b63', fontSize: '25px', fontWeight: 500 }}>
              <i>Захирал Цагаандоржийн Баясгалан</i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Greeting;
