import React from "react";
import styles from "./Header.module.css";
import { CiMail } from "react-icons/ci";
import { FaMap } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <div className={styles.item}>
          <CiMail size={20} color="#fff" />
          bayankhongortseverhot@gmail.com
        </div>
        <div className={styles.item}>
          <FaMap size={15} color="#fff" />
          Монгол улс, Баянхонгор аймаг, Баянхонгор сум
        </div>
        <div className={styles.item}>
          <FaPhone size={15} color="#fff" />
          +976 70444200
        </div>
      </div>
      <div className={styles.socials}>
        <div style={{ width: "45px" }} className="divider"></div>
        <p>Сошиал хаяг</p>
        <div className={styles.soc_div}
          style={{ background: "#1772eb" }}
        >
          <FaFacebookF
            className={styles.social_icon}
          />
        </div>
        <div className={styles.soc_div}
          style={{ background: "#2ca5db" }}
        >
          <FaTwitter
            className={styles.social_icon}
          />
        </div>
        <div className={styles.soc_div}
          style={{ background: "#0072b1" }}
        >
          <FaInstagram
            className={styles.social_icon}
          />
        </div>
        <div className={styles.soc_div}
          style={{ background: "#e11b21" }}
        >
          <TfiYoutube
            className={styles.social_icon}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
