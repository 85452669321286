import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Greeting from './Pages/Greeting/Greeting';
import Footer from './Components/Footer/Footer';
import HomeNavbar from './Components/Navbar/HomeNavbar/HomeNavbar';
import Service1 from "./Pages/Service/Service1";
import Service2 from "./Pages/Service/Service2";
import Service3 from "./Pages/Service/Service3";
import Test from './Pages/Service/Test';
import Contact from './Pages/Contact/Contact';
import News from './Pages/News/News';
import NewsDetail from './Pages/News/Detail/NewsDetail';
import MobileNavbar from './Components/Navbar/MobileNavbar';
import ControlPage from './Pages/About/Control/Control';
import RulesPage from './Pages/About/Rules/Rules';
import ResolutionPage from './Pages/About/Resolution/Resolution';
import WorkSearchPage from './Pages/About/WorkSearch/WorkSearch';
import ApplicationPage from './Pages/About/Application/Application';
import EthicsPage from './Pages/About/Ethics/Ethics';
import { useEffect, useState } from 'react';
import axios from 'axios';
import FinancePage from './Pages/Finance/Finance';
import AboutUs from './Pages/About/AboutUs/AboutUs';

function App() {
  const [news, setNews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = 'https://tseverhot.templateapi.xyz/';

  useEffect(() => {
    setError(null);

    Promise.all([
      axios.get(url + 'news').then(res => setNews(res.data.data)),
      axios.get(url + 'categories').then(res => setCategories(res.data.data)),
      axios.get(url + 'finance').then(res => setPages(res.data.data))
    ]).then(() => {
      setIsLoading(false);
    }).catch(err => {
      setError('Failed to load data');
      setIsLoading(false);
      console.error(err);
    });

  }, []);

  if (isLoading) {
    return <div className='loader-screen'><div className="loader"></div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Router>
      {window.innerWidth > 740 ? <HomeNavbar /> : <MobileNavbar />}
      <Routes>
        <Route path='/' element={<Home news={news} />} />
        <Route path='/main/greeting' element={<Greeting />} />
        <Route path="/main/service1" element={<Service1 />} />
        <Route path="/main/service2" element={<Service2 />} />
        <Route path="/main/service3" element={<Service3 />} />
        <Route path="/main/test" element={<Test />} />
        <Route path="/main/contact" element={<Contact />} />
        <Route path="/main/news" element={<News news={news} />} />
        <Route path="/news" element={<News news={news} />} />
        <Route path="/newsdetial/:name" element={<NewsDetail news={news} categories={categories} />} />
        <Route path="/finance/:name" element={<FinancePage finance={pages} />} />
        <Route path="/about/control" element={<ControlPage />} />
        <Route path="/about/rules" element={<RulesPage />} />
        <Route path="/about/resolution" element={<ResolutionPage />} />
        <Route path="/about/worksearch" element={<WorkSearchPage />} />
        <Route path="/about/application" element={<ApplicationPage />} />
        <Route path="/about/ethics" element={<EthicsPage />} />
        <Route path="/about/aboutus" element={<AboutUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
