import React from 'react';
import styles from './Video.module.css'

const YouTubeEmbed = ({ embedId }) => (
    <div className={styles.container}>
        <iframe
            className={styles.video}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube video"
        />
    </div>
);

export default YouTubeEmbed;
