import React from 'react'
import styles from './News.module.css'
import Cover from '../../Uttils/Cover/Cover'
import NewsComponent from '../../Components/News/News'

const News = ({ news }) => {
    // alert(news.length)
    return (
        <div>
            <div className={styles.container}>
                <Cover title={'Бидний мэдээлэл'} />
                <NewsComponent news={news} />
            </div>
        </div>
    )
}

export default News
