import React from 'react';
import { useParams } from 'react-router-dom';
import { FaRegFilePdf } from "react-icons/fa";

const FinancePage = ({ finance }) => {
    const { name } = useParams();

    // Find the current page's data
    const pageData = finance.find((e) => e.page === name);

    return (
        <div className='finance-div'>
            <div style={{ marginTop: '15vh' }}>
                {pageData?.pdfs?.map((e, index) => (
                    <a
                        key={index}
                        href={'https://tseverhot.templateapi.xyz/uploads/' + e.pdf}
                        className='finance-button'
                        download
                    >
                        PDF {index + 1} татах
                        <FaRegFilePdf color='white' size={20} />
                    </a>
                ))}
            </div>
            <p style={{ opacity: '0' }}> .</p>
            {pageData && (
                <div className='div'>
                    <h2 className='big-title'>{pageData.title}</h2>
                    <p className='title'>{pageData.page}</p>
                    <div
                        className='div'
                        dangerouslySetInnerHTML={{ __html: pageData.content }}
                    />
                </div>
            )}
            {pageData?.videos?.map((e, index) => (
                <div key={index} style={{ marginBottom: '30px' }} className='video-container'>
                    <video
                        src={'https://tseverhot.templateapi.xyz/uploads/' + e.video}
                        controls
                        width="100%"
                        height="auto"
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            ))}
        </div>
    );
};

export default FinancePage;
