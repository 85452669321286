import React from "react";
import styles from "./Excel.module.css";
import ImageViewer from "awesome-image-viewer";
import s1table from '../../Assets/service_1_table.png'

const Excel = ({ which }) => {
  const myImages = [
    which == 1
      ? {
        mainUrl: "/excel1.png",
        // description: "Тээврийн хэрэгслийн үзүүлэлт",
      }
      : {
        mainUrl: "/excel2.png",
        // description: "Тээврийн хэрэгслийн үзүүлэлт",
      },
    {
      mainUrl: "/excel3.png",
      // description: "Тээврийн хэрэгслийн үзүүлэлт",
    },
  ];

  const handleOpenImageViewer = () => {
    new ImageViewer({
      images: myImages,
    }).show();
  };

  return (
    <div className={styles.container}>
      <h1 className="text-[#152B63] text-3xl font-bold my-4">
        Тээврийн хэрэгслийн үзүүлэлт
      </h1>
      {which == 1 ? (
        <div className={styles.excel_div}>
          <img src={s1table} className={styles.table} alt="" />
          <button>Татах</button>
        </div>
      ) : (
        <div className={styles.content}>
          <div className="w-full text-center flex flex-col gap-5">
            <span className="w-full text-center w-full font-bold text-[#152B63]">
              ЦЭВЭР ХОТ ОНӨ ААТҮГАЗРААС НИЙТИЙН БОЛОН АМИНЫ ОРОН СУУЦНУУДАД
              ЦЭВЭРЛЭГЭЭ ҮЙЛЧИЛГЭЭ ХИЙЖ БАЙГАА ҮЙЛЧИЛГЭЭНИЙ АЖИЛТНУУДТАЙ
              ХОЛБООТОЙ ТООН МЭДЭЭНИЙ НЭГТГЭЛ
            </span>
            <img
              src="/excel2.png"
              alt=""
              className="w-full"
              onClick={handleOpenImageViewer}
            />
            <button>Татах</button>
          </div>
          <div className="w-full text-center flex flex-col gap-5">
            <span className="text-center font-bold text-[#152B63]">
              ЦЭВЭР ХОТ ОНӨ ААТҮГАЗРААС ОРОН СУУЦНЫ ОРЦНЫ ЦЭВЭРЛЭГЭЭ ҮЙЛЧИЛГЭЭ
              АВДАГ НИЙТИЙН ОРОН СУУЦНУУДЫН ТОО МЭДЭЭНИЙ НЭГТГЭЛ
            </span>
            <img src="/excel3.png" alt="" onClick={handleOpenImageViewer} />
            <button>Татах</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Excel;
