import React from 'react';
import styles from './Footer.module.css'
import logo from '../../Assets/logo.png'
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_content}>
        <div className={styles.footer_left}>
            <img src={logo} />
            <p>Өдөр бүр байгальд хайртай иргэн байгаарай. Хог хаягдлаа ил задгай бүү ханаарай</p>
            <div className={styles.socials}>
            <FaFacebookF className={styles.icon} />
            <FaTwitter className={styles.icon} />
            <FaInstagram className={styles.icon}/>
            <FaYoutube className={styles.icon}/>
            </div>
        </div>
        <div className={styles.footer_center}>
          <ul>
            <h2>Дэд гарчиг</h2>
            <li>
              <a href="#">Ил тод байдал</a>
            </li>
            <li>
              <a href="#">Хүний нөөцийн ил тод байдал</a>
            </li>
            <li>
              <a href="#">Хяналт шинжилгээ үнэлгээний дүн</a>
            </li>
            <li>
              <a href="#">Өргөдөл гомдол шийдвэрлэлт</a>
            </li>
            <li>
              <a href="#">Байгууллагын ёс зүйн дэд хороо</a>
            </li>
          </ul>
          <ul>
            <h2>Агуулга</h2>
            <li>
              <a href="#"><span>Бидний тухай</span></a>
            </li>
            <li>
              <a href="#"><span>Үйлчилгээ</span></a>
            </li>
            <li>
              <a href="#"><span>Хууль эрх зүй</span></a>
            </li>
            <li>
              <a href="#"><span>Хууль эрх зүй</span>˛</a>
            </li>
            <li>
              <a href="#"><span>Холбоо барих</span></a>
            </li>
          </ul>
        </div>
        <div className={styles.footer_right}>
            <h2>Холбоо барих</h2>
          <p>Дав - Баа 09:00-18:00</p>
          <div className={styles.divider}></div>
          <p>Бямба 10:00-16:00</p>
          <div className={styles.divider}></div>
          <p>Ням амарна</p>
        </div>
        
      </div>
      <br/>
      <div className={styles.divider} style={{width: '80vw'}}></div>
      <div className={styles.footer_bottom}>
        <p> Virasoft </p>© 2024. All rights reserved. 
      </div>
      
    </footer>
  );
};

export default Footer;