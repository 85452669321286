import React, { useState } from 'react'
import styles from './Ask.module.css'
import { IoIosAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";

const Ask = () => {
    const [index, setIndex] = useState(0);

    return (
        <div className={styles.container}>
            <div className={styles.titles}>
                <p className="title" style={{ fontSize: '16px' }}>
                    ТҮГЭЭМЭЛ АСУУЛТ ХАРИУЛТ
                </p>
                <h2 className='big-title'>
                    Үйлчлүүлэгчдээс <span style={{ color: '#cd0d13' }}>ирдэг </span>
                    түгээмэл асуулт
                </h2>
            </div>
            <div className={styles.asks}>
                <div className={styles.ask}>
                    <div className={styles.ask_title}
                        onClick={() => {
                            index !== 1 ? setIndex(1) : setIndex(0)
                        }}
                    >
                        <div className={index !== 1 ? styles.ask_ask : styles.ask_ask_click}>
                            <p>
                                Хог хаягдал хэзээ тээвэрлэх вэ?
                            </p>
                            {
                                index !== 1 ? <IoIosAdd
                                    size={window.innerWidth > 700 ? 20 : 25}
                                    onClick={() => setIndex(1)} />
                                    : <FiMinus color='red'
                                        size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(0)} />
                            }
                        </div>
                        {
                            index === 1 && <p className={styles.answer}>
                                Competently repurpose go forward benefits without-oriented conveniently target business opportunities whereas proactive strea content
                            </p>
                        }
                    </div>
                </div>
                <div className={styles.ask}
                    onClick={() => {
                        index !== 2 ? setIndex(2) : setIndex(0)
                    }}
                >
                    <div className={styles.ask_title}>
                        <div className={index !== 2 ? styles.ask_ask : styles.ask_ask_click}>
                            <p>
                                Манайх хогноосоо хоцорчлоо яаж ачуулах вэ?
                            </p>
                            {
                                index !== 2 ? <IoIosAdd
                                    size={window.innerWidth > 700 ? 20 : 25}
                                    onClick={() => setIndex(2)} />
                                    : <FiMinus color='red'
                                        size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(0)} />
                            }
                        </div>
                        {index === 2 && <p className={styles.answer}>
                            Competently repurpose go forward benefits without-oriented conveniently target business opportunities whereas proactive strea content
                        </p>}
                    </div>
                </div>
                <div className={styles.ask}
                    onClick={() => {
                        index !== 3 ? setIndex(3) : setIndex(0)
                    }}
                >
                    <div className={styles.ask_title}>
                        <div className={index !== 3 ? styles.ask_ask : styles.ask_ask_click}>
                            <p>
                                Манай багт хог ачиж байгаа жолоочийн дугаар хэд вэ?
                            </p>
                            {
                                index !== 3 ? <IoIosAdd size={window.innerWidth > 700 ? 20 : 25}
                                    onClick={() => setIndex(3)} />
                                    : <FiMinus color='red'
                                        size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(0)} />
                            }
                        </div>
                        {index === 3 && <p className={styles.answer}>
                            Competently repurpose go forward benefits without-oriented conveniently target business opportunities whereas proactive strea content
                        </p>}
                    </div>
                </div>
                <div className={styles.ask}
                    onClick={() => {
                        index !== 4 ? setIndex(4) : setIndex(0)
                    }}
                >
                    <div className={styles.ask_title}>
                        <div className={index !== 4 ? styles.ask_ask : styles.ask_ask_click}>
                            <p>
                                Манай байгууллагын хог хаягдлыг хэд хэддэх өдөр ачаад байгаа юм бэ?
                            </p>
                            {
                                index !== 4 ? <IoIosAdd size={window.innerWidth > 700 ? 20 : 25}
                                    onClick={() => setIndex(4)} />
                                    : <FiMinus color='red'
                                        size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(0)} />
                            }
                        </div>
                        {index === 4 && <p className={styles.answer}>
                            Competently repurpose go forward benefits without-oriented conveniently target business opportunities whereas proactive strea content
                        </p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ask
