import React from 'react'
import styles from './Resolution.module.css'
import pdf1 from '../../../Assets/About/1.png'
import pdf2 from '../../../Assets/About/2.png'
import pdf3 from '../../../Assets/About/3.png'
import pdf4 from '../../../Assets/About/4.png'
import pdf5 from '../../../Assets/About/5.png'
import img1 from '../../../Assets/About/z1.jpg'
import img2 from '../../../Assets/About/z2.jpg'
import img3 from '../../../Assets/About/z3.jpeg'

const ResolutionPage = () => {
    return (
        <div className={styles.container}>
            <div>
                <div className="title">Сумын иргэдийн хурлын тогтоол:</div>
                <img src={pdf1} alt="" />
                <img src={pdf2} alt="" />
                <img src={pdf3} alt="" />
                <img src={pdf4} alt="" />
                <img src={pdf5} alt="" />
            </div>
            <div>
                <div className="title">Баянхонгор сумын иргэдийн хурлын тогтоол:</div>
                <img src={img1} alt="" />
                <img src={img2} alt="" />
            </div>
            <div>
                <div className="title">Захиргааны хэм хэмжээний акт:</div>
                <img src={img3} alt="" />
            </div>
        </div>
    )
}

export default ResolutionPage
