import React from 'react'
import styles from './HomeNews.module.css'
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import img1 from '../../../Assets/slider1.jpeg'
import img2 from '../../../Assets/slider2.jpeg'
import img3 from '../../../Assets/slider3.jpeg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

const HomeNews = () => {

    const handleSlideChange = () => {
        console.log('slide change');
    };

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.items_right}>
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: true,
                        }}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={handleSlideChange}
                    >
                        <SwiperSlide className={styles.swiper}>
                            <Link className={styles.swiper}>
                                <img src={img1} alt="" />
                                <p> </p>
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper}>
                            <div className={styles.swiper}>
                                <img src={img2} alt="" />
                                <p> </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper}>
                            <div className={styles.swiper}>
                                <img src={img3} alt="" />
                                <p> </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={styles.items_left}>
                    <div className={styles.left_item}>
                        <img src={img1} alt="" />
                        <p> </p>
                    </div>
                    <div className={styles.left_item}>
                        <img src={img2} alt="" />
                        <p> </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNews
