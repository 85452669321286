import React from "react";
import styles from "./Intro.module.css";
import icon from '../../Assets/abo-icon.png'
import call from '../../Assets/call-icon.png'

const Intro = ({ which, img }) => {
  return (
    <div className={styles.body}>
      <div
        style={{
          zIndex: "0",
          width: which === 1 ? '13vw' : "20vw",
          position: "absolute",
          left: "0",
          height: which == 1 ? "150vh" : "40%",
          background: "#346EFD",
          borderTopRightRadius: "80px",
          borderBottomRightRadius: "30px",
          display: window.innerWidth > 640 ? '' : 'none'
          // marginTop: "100px"
        }}
      ></div>
      <div className={styles.container}>
        <div className="" style={{ zIndex: 0, width: window.innerWidth > 640 ? "40vw" : '80vw' }}>
          <img
            src={img}
            alt=""
            style={{
              width: window.innerWidth > 640 ? "40vw" : '80vw',
            }}
          />
        </div>
        <div className={styles.items}>
          <span className={styles.about_text}>Бидний тухай</span>
          {which == 1 ? (
            <h1 className={styles.about_title}>
              Хог хаягдал <span className={styles.about_text}>Тээвэрлэлт</span>{" "}
              засвар үйлчилгээний хэсгийн тухай
            </h1>
          ) : which == 2 ? (
            <h1 className={styles.about_title}>
              Орон сууц <span className={styles.about_text}>Орц</span>{" "}
              цэвэрлэгээ, үйлчилгээ
            </h1>
          ) : (
            <h1 className={styles.about_title}>
              Хог хаягдал <span className={styles.about_text}>Тээвэрлэлт</span>{" "}
              засвар үйлчилгээний хэсгийн тухай
            </h1>
          )}
          {which == 1 ? (
            <p className={styles.about_paragraph}>
              Тус байгууллагын хог хаягдал тээвэрлэлтийн хэсэгт хог хаягдал
              тээвэрлэлтийн жолооч 13, хог ачигч 18 нийт 35 ажилтан албан
              хаагчдын бүрэлдэхүүнтэйгээр 18 тээврийн хэрэгсэлээр жил бүр
              Баянхонгор сумын гэр хорооллын 8 багийн 8610 өрх, төвийн хорооллын
              2 багийн орон сууцны 2314 өрх, төрийн болон хувийн хэвшил,
              хувиараа хөдөлмөр эрхэлдэг 350 гаруй аж ахуй нэгж байгууллагуудын
              хог хаягдлыг тогтмол тээвэрлэн ажиллаж байна. Төрийн байгууллага
              болон хувийн хэвшлийн аж ахуй нэгж, дэлгүүрүүдтэй жил бүр хог
              хаягдал тээвэрлэх гэрээг тогтмол байгуулан ажиллаж хог хаягдлыг
              ачиж тээвэрлэдэг. Жилдээ 3700- 4258 удаагийн рейс хийж,
              35000-45000 мянган тн хог хаягдлыг нэгдсэн хогийн цэгт хүлээн авч
              дарж булшлах үйл ажиллагааг тогтмол зохион байгуулж байна. 2023
              онд хог хаягдлыг ангилах ажлыг зохион байгуулан үнс 2844, хуванцар
              14 тн, ажилласан тос масло уурхайгаас 500 литрийг хүлээн авч
              ангилан ялгаж, хог хаягдлыг 21%-р бууруулан хөрсний бохирдлоос
              сэргийлэн ажиллаж байна.
            </p>
          ) : which == 2 ? (
            <p className={styles.about_paragraph}>
              Орон сууцны орц цэвэрлэгээ үйлчилгээний ажлыг 2014 оноос эхлэн
              хийж эхэлсэн бөгөөд анх 58 байрны нийт 680 айл өрхийн орцны
              цэвэрлэгээ үйлчилгээг 7 үйлчлэгч хариуцан хийдэг байсан бол 2024
              онд 100 байрны 2722 айл өрхийн орцны цэвэрлэгээ үйлчилгээг 12
              үйлчлэгч хариуцан хийж байна. Цэвэрлэгээ үйлчилгээний хөлсөнд нэг
              айл өрхөөс сарын 10000 мянган төгрөгийг төлбөрийг айл өрхийн
              гэрлийн төлбөр дээр хамтатган бодуулж Баянхонгор Эрчим хүч ЦТ
              ХХК-иар дамжуулан орлогыг төвлөрүүлдэг.
            </p>
          ) : (
            <p className={styles.about_paragraph}>
              Тус байгууллагын хог хаягдал тээвэрлэлтийн хэсэгт хог хаягдал
              тээвэрлэлтийн жолооч 13, хог ачигч 18 нийт 35 ажилтан албан
              хаагчдын бүрэлдэхүүнтэйгээр 18 тээврийн хэрэгсэлээр жил бүр
              Баянхонгор сумын гэр хорооллын 8 багийн 8610 өрх, төвийн хорооллын
              2 багийн орон сууцны 2314 өрх, төрийн болон хувийн хэвшил,
              хувиараа хөдөлмөр эрхэлдэг 350 гаруй аж ахуй нэгж байгууллагуудын
              хог хаягдлыг тогтмол тээвэрлэн ажиллаж байна. Төрийн байгууллага
              болон хувийн хэвшлийн аж ахуй нэгж, дэлгүүрүүдтэй жил бүр хог
              хаягдал тээвэрлэх гэрээг тогтмол байгуулан ажиллаж хог хаягдлыг
              ачиж тээвэрлэдэг. Жилдээ 3700- 4258 удаагийн рейс хийж,
              35000-45000 мянган тн хог хаягдлыг нэгдсэн хогийн цэгт хүлээн авч
              дарж булшлах үйл ажиллагааг тогтмол зохион байгуулж байна. 2023
              онд хог хаягдлыг ангилах ажлыг зохион байгуулан үнс 2844, хуванцар
              14 тн, ажилласан тос масло уурхайгаас 500 литрийг хүлээн авч
              ангилан ялгаж, хог хаягдлыг 21%-р бууруулан хөрсний бохирдлоос
              сэргийлэн ажиллаж байна.
            </p>
          )}
          {which == 1 ? (
            <ul className={styles.ul}>
              <li className={styles.li}>
                3-р хэсэг буюу орон сууцны цэвэрлэгээ үйлчилгээний үйлчилгээний
                хэсэг
              </li>
              <li className={styles.li}>
                Рашаант 10-р багийн "Багийн хөгжлийн төв"-д үйл ажиллагаа явуулж
                байна
              </li>
            </ul>
          ) : which == 2 ? (
            <ul className={styles.ul}>
              <li className={styles.li}>
                2-р хэсэг буюу хог хаягдал тээвэрлэлт, үйлчилгээний хэсэг
              </li>
              <li className={styles.li}>
                Есөнбулаг 9-р байгийн төв зам дагуу байрлах оффисд үйл ажиллагаа
                явуулж байна
              </li>
            </ul>
          ) : (
            <ul className={styles.ul}>
              <li className={styles.li}>
                2-р хэсэг буюу хог хаягдал тээвэрлэлт, үйлчилгээний хэсэг
              </li>
              <li className={styles.li}>
                Есөнбулаг 9-р байгийн төв зам дагуу байрлах оффисд үйл ажиллагаа
                явуулж байна
              </li>
            </ul>
          )}
          <div className={styles.about}>
            <img src={icon} alt="" />
            <div className={styles.column} style={{ gap: 0 }}>
              <h2>37</h2>
              <p>37 хүний бүрэлдэхүүн</p>
            </div>
          </div>
          <div className={styles.call}>
            <img src={call} alt="" />
            <div className={styles.column}>
              <p>Залгах</p>
              <h3>+976 9110-4200</h3>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

export default Intro;
