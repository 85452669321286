import React from 'react'
import styles from './About.module.css'
import img from '../../../Assets/about-bg.jpg'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <div className={styles.container} style={{ backgroundImage: `url(${img})` }}>
            <div className={styles.img_div}>
                <img src="https://tseverhot2.fununity.net/wp-content/uploads/2024/04/Frame-15-1.png" alt="" />
            </div>
            <div className={styles.content}>
                <p className={styles.title}>БИДНИЙ ТУХАЙ</p>
                <div className={styles.titles}>
                    <h2 color='#152b63' style={{ color: '#152b63' }}>Бид салбартаа</h2>
                    <h2 color='#d9242a' style={{ color: '#d9242a' }}>10 жилийн туршлага</h2>
                </div>
                <p className={styles.text}>
                    Манай байгууллага нь 2014 оноос эхлэн үйл ажиллагааны чиглэлээрээ салж, Баянхонгор аймгийн ИТХ-ын
                    2016 оны 09 дүгээр сарын 28-ны өдрийн
                    85 дугаар тогтоолоор Цэвэр хот ОНӨ ХХКомпани, 2019 оны 12 дугаар сарын 25-ний өдрийн 134 дүгээр тогтоолоор
                    Цэвэр хот ОНӨ
                    ААТҮГазар болон өмчийн хэлбэрээ өөрчлүүлэн үйлчилгээг хариуцсан 3 хэсэгтэй 60 орон тоотойгоор
                    3 хэсэг газар үйл ажиллагаагаа явуулж байна.
                </p>
                <div>
                    <Link to={'/about/aboutus'} className={styles.button}>Дэлгэрэнгүй</Link>
                </div>
            </div>
        </div>
    )
}

export default About
