import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Cover from "../../Uttils/Cover/Cover";

const Service3 = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <Cover title={"Хог Хаягдалт Тээвэрлэлт - Tseverhot2.Fununity.Net"} path1={'HOME'} path2={'НЭГДСЭН ХОГИЙН ЦЭГ'} />
      <div className="w-full container flex flex-col items-center px-40 max-md:w-screen">
        <span className="flex text-[#FF531F] gap-10 items-center mt-20 mb-6">
          <div className="flex ">
            <BiChevronRight size={20} />
            <BiChevronRight size={20} />
            <BiChevronRight size={20} />
          </div>
          <span className="font-bold text-lg">Хогийн цэг</span>
          <div className="flex ">
            <BiChevronLeft size={20} />
            <BiChevronLeft size={20} />
            <BiChevronLeft size={20} />
          </div>
        </span>
        <span className=" text-center font-bold text-4xl mb-20 max-md:text-xl max-md:w-screen">
          Баянхонгор аймаг <br />
          Цэвэр хот ОНӨ ААТҮГ
        </span>
        <span className="text-3xl text-[#152B63] font-bold mb-8 max-md:text-xl max-md:w-screen max-md:pl-10">
          Нэгдсэн хуучин хогийн цэг
        </span>
        <p className="text-justify  text-gray-500 text-[11pt] max-md:w-screen max-md:p-10">
          Баянхонгор аймгийн Баян-Овоо сумын 2 дугаар багийн Улаан-Эрэг газарт
          байрлах хуучин хогийн цэг 2009 онд ашиглалтад орж 2023 оны 04 дүгээр
          сард бүрэн дүүрсэн. Уг нэгдсэн хогийн цэгийн нөхөн сэргээлтийн ажлыг
          аймгийн Хөгжлийн бодлого, төлөвлөлт, хөрөнгө оруулалтын хэлтсээс
          тендерийн ажлыг зохион байгуулан 60,0 сая төгрөгийн өртөгтэй ажил
          гүйцэтгэх гэрээнд Талын нөмөр ХХКомпани шалгарч техникийн нөхөн
          сэргээлтийн ажлыг 2023.04.23-04.26-ний өдрүүдэд хийж гүйцэтгэсэн.
          <br />
          Нэгдсэн хогийн цэгт техникийн нөхөн сэргээлтийн ажлыг хийж эхэлсэнтэй
          холбогдуулан хогийн цэгийн эргэн тойрон дахь 100м хүртэл газрын хур
          хог хаягдал, гуу жалганд хуримтлагдсан хог хаягдлыг цэвэрлэх, дарж
          булшлах ажлыг тус байгууллагын 46 ажилтан 3 хэсэг болж гар аргаар
          болон хүнд даацын техникүүд ковш, норд, экскеваторыг ашиглан 2023 оны
          4 дүгээр сарын 25-ны өдрөөс 5 дугаар сарын 04-ны өдрийг хүртэлх
          хугацаанд хийж гүйцэтгэсэн. Хог хаягдлыг тээвэрлэхэд кама, маяти 2
          автомашинуудыг ашиглан 10 хоногт 628,5 тн хур хог хаягдлыг цэвэрлэн
          дарж булшилхад 6 тээврийн хэрэгсэлд 1340 литр дт, А/92 шатахуун 115
          литрийг зарцуулж нийт 5,5 сая төгрөгний шатахууны зардлыг гаргаж
          ажилласан.
        </p>
        <div className="w-full flex gap-10 mt-8 max-md:flex-col max-md:w-screen max-md:items-center">
          <span className="text-3xl text-[#152B63] font-bold mb-8 max-md:w-screen max-md:text-xl max-md:text-center ">
            Нэгдсэн шинэ хогийн цэг
          </span>
          <img
            src="/negdsen.png"
            alt=""
            className="w-[150px] ml-40 object-cover max-md:w-[90vw] max-md:ml-0"
          />
        </div>
        <div className="w-full flex gap-10 mt-2 max-md:flex-col max-md:w-screen max-md:items-center ">
          <img
            src="/negdsen1.png"
            alt=""
            className="w-[150px] ml-40 object-cover max-md:w-[90vw] max-md:ml-0"
          />
          <span className="text-justify  text-gray-500 text-[11pt] max-md:p-5">
            Баянхонгор сумын төвлөрсөн шинэ хогийн цэгийг шинэ суурьшлийн бүсээс
            13 км зайд Өлзийт сумын 1 дүгээр багийн нутаг дэвсгэрт 70 метрийн
            өргөнтэй, 100 метрийн урттай шинэ хогийн цэгийг орон нутгийн
            хөгжлийн сангийн 200.0 сая төгрөгийн хөрөнгө оруулалтаар санхүүгийн
            асуудлыг шийдвэрлэн “Талын нөмөр” ХХКомпани гүйцэтгэж 2023 оны 4
            дүгээр сараас эхлэн ашиглалтад оруулсан бөгөөд 2023-2024 онуудад
            хогийн цэгт 26686 тн хог хаягдлыг дарж булшлаад байна.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Service3;
