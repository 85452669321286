import React from 'react'
import styles from './Service1FeedBack.module.css'
import bg from '../../../Assets/service1_contact_icon.png'
import { PiPhoneCallLight } from "react-icons/pi";

const Service1FeedBack = () => {
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.title}>
                    <div className="big-title">Хог хаягдлын тухай хууль</div>
                    <button>Татах</button>
                </div>
                <div className={styles.contact}>
                    {window.innerWidth > 640 ? <div className={styles.img_content} style={{
                        backgroundImage:
                            `url(${bg})`
                    }} >
                        <p></p>
                        <p className={styles.number}>
                            <PiPhoneCallLight className={styles.icon} size={70} />
                            Залгах: +976 9110-4200
                        </p>
                    </div> : null}
                    <div className={styles.form_content}>
                        <p className={styles.mini_title}>Холбоо Барих</p>
                        <h3 className={styles.title_row}>Таны санал хүсэлт <span style={{ color: '#fd8e16' }}> Бидний </span > үнэт зүйл байх болно</h3>
                        <div className={styles.row}>
                            <input type="text" placeholder='Таны нэр' name="" id="" />
                            <input type="text" placeholder='Таны и-мэйл' name="" id="" />
                        </div>
                        <select>
                            <option value="">Агуулга сонго</option>
                        </select>
                        <textarea placeholder='Агуулга' cols="30" rows="5"></textarea>
                        <button>Илгээх</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service1FeedBack
