import React from 'react'
import styles from './Service1Driver.module.css'
import bg from '../../../Assets/service1-bg.jpeg'
import icon from '../../../Assets/service1_card_icon.png'
import img1 from '../../../Assets/cars/1.jpg'
import img2 from '../../../Assets/cars/2.jpg'
import img3 from '../../../Assets/cars/3.jpg'
import img4 from '../../../Assets/cars/4.jpg'
import img5 from '../../../Assets/cars/5.jpg'
import img6 from '../../../Assets/cars/6.jpg'
import img7 from '../../../Assets/cars/7.jpg'
import img8 from '../../../Assets/cars/8.jpg'
import img9 from '../../../Assets/cars/9.jpg'
import img10 from '../../../Assets/cars/10.jpg'
import img11 from '../../../Assets/cars/11.jpg'
import img12 from '../../../Assets/cars/12.jpg'
import img13 from '../../../Assets/cars/13.jpg'
import img14 from '../../../Assets/cars/14.jpg'
import img15 from '../../../Assets/cars/15.jpg'

const Service1Driver = () => {

    const data = [
        {
            img: img1,
            title: `ЗИЛ-555 
                07-32 БНА
                Өөрөө өргөгч 
                8 тн даацтай
                Эрдэнэмандал 2-р багт хог хаягдал тээвэрлэдэг`
        },
        {
            img: img2,
            title: `Исузу порд 
                    31-46 БНХ
                    Өөрөө өргөгч
                    3.5 тн даацтай  
                    Есөнбулаг 9-р багт хог хаягдал тээвэрлэдэг`
        },
        {
            img: img3,
            title: `Норд бенз
00-42 БНР
Өөрөө өргөгч
17 тн даацтай
Угалз 4-р багт хог
хаягдал тээвэрлэдэг`
        },
        {
            img: img4,
            title: `Норд бенз
00-43 БНР
Өөрөө өргөгч
17 тн даацтай
Цагаанчулуут 5-р багт
хог хаягдал тээвэрлэдэг`
        },
        {
            img: img5,
            title: `41-15 БНХ
Өөрөө шахагч
5 тн даацтай
Рашаант 10-р багт хог
хаягдал тээвэрлэдэг`
        },
        {
            img: img6,
            title: `50 ковш
23-13 УБ
Нэгдсэн хогийн цэгийн
хог хаягдлыг түрэх,
дарж булшлах үүрэгтэй`
        },
        {
            img: img7,
            title: `Кантер мицубиши
52-35 БНА
Өөрөө шахагч
3.5 тн даацтай
Номгон 1-р багт хог
хаягдал тээвэрлэдэг`
        },
        {
            img: img8,
            title: `Кантер мицубиши
59-17 БНА
Өөрөө шахагч
3 тн даацтай
Номгон 1-р багт хог
хаягдал тээвэрлэдэг`
        },
        {
            img: img9,
            title: `Маяти-2
37-96 БНХ
Өөрөө өргөгч
4.5 тн даацтай
Гэгээн шавь 6 -р багт
хог хаягдал тээвэрлэдэг`
        },
        {
            img: img10,
            title: `Маяти-2
32-16 БНХ
Өөрөө өргөгч
4.5 тн даацтай
Цахир 7-р багт хог
хаягдал тээвэрлэдэг`
        },
        {
            img: img11,
            title: `Исузу элф
31-76 БНХ
Өөрөө өргөгч
3.5 тн даацтай
Албан байгууллага аж
ахуй нэгжүүдээс ирсэн
дуудлага барагдуулдаг`
        },
        {
            img: img12,
            title: `Кама
40-89 БНХ
Өөрөө өргөгч
4.5 тн даацтай
Дуурсах 3-р багт хог
хаягдал тээвэрлэдэг`
        },
        {
            img: img13,
            title: `Паб
96-08 БНХ
Өөрөө өргөгч
15 тн даацтай
Есөнбулаг 9-р багт хог
хаягдал тээвэрлэдэг.`
        },
        {
            img: img14,
            title: `Портер
41-55 БНХ
2 тн даацтай
Аюултай хог хаягдал
тээвэрлэдэг.`
        },
        {
            img: img15,
            title: `Дугуйт экскаватор
13-90 УБ
Газар шорооны ажил`
        },
    ]
    return (
        <div>
            <div className={styles.container} style={{ backgroundImage: `url(${bg})` }}>
                <p style={{ color: 'orange' }}>Үйлчилгээ</p>
                <h2>Цэвэр хот хог хаягдал тээвэрлэлт</h2>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <div className={styles.img_div}></div>
                        <img src={icon} alt="" className={styles.icon} />
                        <div className={styles.bg} style={{ backgroundImage: `url(https://tseverhot2.fununity.net/wp-content/uploads/2024/04/bluesky1.jpeg)` }}></div>
                        <div className={styles.bghover} style={{ backgroundImage: `url(https://tseverhot2.fununity.net/wp-content/uploads/2024/04/hrshhrrhr.jpg)` }}></div>
                        {/* <img src={} className={styles.bg} alt="" /> */}
                        <p className={styles.card_title}>Цэвэр хот ОНӨ ААТҮГ</p>
                        <p className={styles.card_text}>Өдөр бүр байгальд хайртай иргэн байгаарай.</p>
                    </div>
                    {data.map((e) => (
                        <div className={styles.card}>
                            <div className={styles.img_div}></div>
                            <img src={icon} alt="" className={styles.icon} />
                            <div className={styles.bg} style={{ backgroundImage: `url(https://tseverhot2.fununity.net/wp-content/uploads/2024/04/bluesky1.jpeg)` }}></div>
                            <div className={styles.bghover} style={{ backgroundImage: `url(${e.img})` }}></div>
                            {/* <img src={} className={styles.bg} alt="" /> */}
                            <p className={styles.card_title}>Цэвэр хот ОНӨ ААТҮГ</p>
                            <p className={styles.card_text}>{e.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Service1Driver
