import React from 'react'
import styles from '../Unit.module.css'
import img1 from '../../../Assets/About/etik1.png'
import img2 from '../../../Assets/About/etik2.png'
import rimg1 from '../../../Assets/About/ethic_rule1.png'
import rimg2 from '../../../Assets/About/ethic_rule2.png'
import rimg3 from '../../../Assets/About/ethic_rule3.png'
import rimg4 from '../../../Assets/About/ethic_rule4.png'
import eimg1 from '../../../Assets/About/erule1.png'
import eimg2 from '../../../Assets/About/erule2.png'
import eimg3 from '../../../Assets/About/erule3.png'

const EthicsPage = () => {
    return (
        <div className={styles.contaienr}>
            <h3 className="title">Байгууллагын ёс зүйн дэд хороо</h3>
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <h3 className="title">Ёс зүйн хорооны ажилчдын журам</h3>
            <img src={eimg1} alt="" />
            <img src={eimg2} alt="" />
            <img src={eimg3} alt="" />
            <h3 className="title">Ёс зүйн дэд хорооны ажиллах дүрэм</h3>
            <img src={rimg1} alt="" />
            <img src={rimg2} alt="" />
            <img src={rimg3} alt="" />
            <img src={rimg4} alt="" />
        </div>
    )
}

export default EthicsPage
