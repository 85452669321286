import React from 'react'
import styles from './Contact.module.css'
import Cover from '../../Uttils/Cover/Cover';
import img from '../../Assets/greeting_cover.jpeg';
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Circle, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

const Contact = () => {
    const position = [46.20129255786697, 100.71578538970236]
    return (
        <div className={styles.container}>
            <Cover img={img} path1={'HOME'} path2={'МЭНДЧИЛГЭЭ'} title={'Мэндчилгээ - Tseverhot2.Fununity.net'} />
            <div className={styles.content}>
                <div className={styles.forms}>
                    <h3 className={styles.minihead}>
                        Таны санал хүсэлт бидний үнэт зүйлс байх болно
                    </h3>
                    <div className={styles.forms_row}>
                        <input type='text' placeholder='Таны нэр*' />
                        <input type='text' placeholder='Таны и-мэйл*' />
                    </div>
                    <input type='number' placeholder='Утасны дугаар*' />
                    <p>
                        <input type='text' placeholder='Агуулга' />
                    </p>
                    <p>
                        <input type='text' placeholder='Текст' />
                    </p>
                    <div className={styles.button}>
                        <button>Илгээх</button>
                    </div>
                </div>
                {window.innerWidth > 640 ? <div className={styles.about}>
                    <h3 className='big-title' style={{ fontSize: '26px' }}>Холбоо барих</h3>
                    <div className={styles.icons}>
                        <div className={styles.icon_div}>
                            <HiOutlineMailOpen className={styles.icon} size={55} color='#fff' />
                            <div className={styles.column}>
                                <h2>И-мэйл</h2>
                                <p>bayankhongortseverhot@gmail.com</p>
                            </div>
                        </div>
                        <div className={styles.icon_div}>
                            <BiPhoneCall className={styles.icon} size={55} color='#fff' />
                            <div className={styles.column}>
                                <h2>Залгах</h2>
                                <p>(976)70444200,90104200</p>
                            </div>
                        </div>
                        <div className={styles.icon_div}>
                            <GrLocation className={styles.icon} size={55} color='#fff' />
                            <div className={styles.column}>
                                <h2>Байршил</h2>
                                <p>Баянхонгор аймаг Баянхонгор сум Есөнбулаг 9-р баг тээвэрчдийн-4 450а тоот</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.socials}>
                        <FaFacebookF className={styles.social} />
                        <FaTwitter className={styles.social} />
                        <FaYoutube className={styles.social} />
                        <FaInstagram className={styles.social} />
                    </div>
                </div> : null}
            </div>
            <MapContainer style={{ width: '100vw', height: '60vh' }} center={position} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Circle center={position} radius={3000} />
                {/* <Marker position={position}> */}
                {/* <Popup> */}

                {/* </Popup> */}
                {/* </Marker> */}
            </MapContainer>,
        </div>
    )
}

export default Contact;