import React, { useState } from 'react';
import { BiMenu, BiX, BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const MobileNavbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [id, setSelectedId] = useState('');

    const handleClick = () => {
        setShowMenu(!showMenu);
    };

    const handleCategoryClick = (index) => {
        setSelectedIndex(selectedIndex === index ? null : index);
    };
    const links = [
        {
            title: "Нүүр",
            path: "/",
            //   nested: false,
        },
        {
            title: "Бидний тухай",
            path: '',
            nested: [{ title: "Мэндчилгээ", path: "/main/greeting" }],
        },
        {
            title: "Үйлчилгээ",
            path: "",
            nested: [
                { title: "Хог Хаягдал тээвэрлэлт", path: "/main/service1" },

                { title: "Орон сууцны орц цэвэрлэгээ", path: "/main/service2" },

                { title: "Нэгдсэн хогийн цэг", path: "/main/service3" },
            ],
        },
        {
            title: "Ил тод байдал",
            path: "",
            nested: [
                {
                    title: "Үйл ажиллагаа",
                    path: "/",
                    mininested: [
                        {
                            title: "Гүйцэтгэлийн төлөвлөгөө биелэлт",
                            path: "/",
                        },
                        {
                            title: "Өргөдөл гомдол шийдвэрлэлт",
                            path: "/about/application",
                        }
                    ]
                },
                {
                    title: "Хүний нөөц",
                    path: "/",
                    mininested: [
                        {
                            title: "Ёс зүйн дэд хороо мөрдөх дүрэм, журам",
                            path: "/about/ethics",
                        },
                        {
                            title: "Хүний нөөцийн хөтөлбөр хэрэгжилт",
                            path: "/",
                        },
                        {
                            title: "Мэдээ тайлан",
                            path: "/",
                        },
                        {
                            title: "Албан хаагчийн ажлын гүйцэтгэлийг үнэлэх журам",
                            path: "/",
                        },
                        {
                            title: "Албан тушаалын тодорхойлолт",
                            path: "/",
                        },
                        {
                            title: "Ажлын байрны сул орон тоо",
                            path: "/about/worksearch",
                        },
                        {
                            title: "Холбоо барих утсаны дугаар",
                            path: "/",
                        }
                    ]
                },
                {
                    title: "Төсөв санхүү",
                    path: "/",
                    mininested: [
                        {
                            title: "Тухайн жилийн төсөв",
                            path: "/finance/Тухайн жилийн төсөв",
                        },
                        {
                            title: "Өмнөх оны төсвийн гүйцэтгэл",
                            path: "/finance/Өмнөх оны гүйцэтгэлийн төсөв",
                        },
                        {
                            title: "Төсвийн гүйцэтгэлийн мэдээ",
                            path: "/finance/Төсвийн гүйцэтгэлийн мэдээ",
                        },
                        {
                            title: "Төсвийн төсөл, төсвийн хуваарь",
                            path: "/finance/Төсвийн төсөл, төсвийн хуваарь",
                        },
                        {
                            title: "Санхүүгийн тайлан",
                            path: "/finance/Санхүүгийн тайлан",
                        },
                        {
                            title: "Аудитын дүгнэлт",
                            path: "/finance/Аудитын дүгнэлт",
                        },
                        {
                            title: "Хог хаягдал тээвэрлэлтийн гэрээ",
                            path: "/finance/Хог хаягдал тээвэрлэлтийн гэрээ",
                        }
                    ]
                },
                {
                    title: "Төлөөлөн удирдах зөвлөл",
                    path: "/",
                    mininested: [
                        {
                            title: "Дүрэм журам",
                            path: "/about/rules",
                        },
                        {
                            title: "Тогтоол шийдвэр",
                            path: "/about/resolution",
                        }
                    ]
                },
                {
                    title: "Хяналт шинжилгээ үнэлгээ",
                    path: "/about/control"
                }
            ],
        },
        {
            title: "Хууль, эрх зүй",
            path: "",
            nested: [
                { title: "Эрх зүйн акт", path: "https://legalinfo.mn/mn", link: "https://legalinfo.mn/mn" },

                { title: "Хог хаягдлын тухай", path: "https://legalinfo.mn/mn", link: "https://legalinfo.mn/mn" },
            ],
        },
        {
            title: "Шилэн данс",
            path: "https://shilendans.gov.mn/",
            link: "https://shilendans.gov.mn/",
            nested: false,
        },
        {
            title: "Мэдээ мэдээлэл",
            path: "/main/news",
            nested: false,
        },
        {
            title: "Холбоо барих",
            path: "/main/contact",
            nested: false,
        },
    ];
    // const links = [
    //     {
    //         title: "Нүүр",
    //         path: "/",
    //         //   nested: false,
    //     },
    //     {
    //         title: "Бидний тухай",
    //         path: '',
    //         nested: [{ title: "Мэндчилгээ", path: "/greeting" }],
    //     },
    //     {
    //         title: "Үйлчилгээ",
    //         path: "",
    //         nested: [
    //             { title: "Хог Хаягдал тээвэрлэлт", path: "/service1" },

    //             { title: "Орон сууцны орц цэвэрлэгээ", path: "/service2" },

    //             { title: "Нэгдсэн хогийн цэг", path: "/service3" },
    //         ],
    //     },
    //     {
    //         title: "Ил тод байдал",
    //         path: "",
    //         nested: [
    //             {
    //                 title: "Үйл ажиллагаа",
    //                 path: "/",
    //                 mininested: [
    //                     {
    //                         title: "Гүйцэтгэлийн төлөвлөгөө биелэлт",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Өргөдөл гомдол шийдвэрлэлт",
    //                         path: "/",
    //                     }
    //                 ]
    //             },
    //             {
    //                 title: "Хүний нөөц",
    //                 path: "/",
    //                 mininested: [
    //                     {
    //                         title: "Ёс зүйн дэд хороо мөрдөх дүрэм, журам",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Хүний нөөцийн хөтөлбөр хэрэгжилт",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Мэдээ тайлан",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Албан хаагчийн ажлын гүйцэтгэлийг үнэлэх журам",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Албан тушаалын тодорхойлолт",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Ажлын байрны сул орон тоо",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Холбоо барих утсаны дугаар",
    //                         path: "/",
    //                     }
    //                 ]
    //             },
    //             {
    //                 title: "Төсөв санхүү",
    //                 path: "/",
    //                 mininested: [
    //                     {
    //                         title: "Тухайн жилийн төсөв",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Өмнөх оны төсвийн гүйцэтгэл",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Төсвийн гүйцэтгэлийн мэдээ",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Төсвийн төсөл, төсвийн хуваарь",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Санхүүгийн тайлан",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Аудитын дүгнэлт",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Хог хаягдал тээвэрлэлтийн гэрээ",
    //                         path: "/",
    //                     }
    //                 ]
    //             },
    //             {
    //                 title: "Төлөөлөн удирдах зөвлөл",
    //                 path: "/",
    //                 mininested: [
    //                     {
    //                         title: "Дүрэм журам",
    //                         path: "/",
    //                     },
    //                     {
    //                         title: "Тогтоол шийдвэр",
    //                         path: "/",
    //                     }
    //                 ]
    //             },
    //             {
    //                 title: "Хяналт шинжилгээ үнэлгээ",
    //                 path: "/"
    //             }
    //         ],
    //     },
    //     {
    //         title: "Хууль, эрх зүй",
    //         path: "",
    //         nested: [
    //             { title: "Эрх зүйн акт", path: "https://legalinfo.mn/mn" },

    //             { title: "Хог хаягдлын тухай", path: "https://legalinfo.mn/mn" },
    //         ],
    //     },
    //     {
    //         title: "Шилэн данс",
    //         path: "",
    //         nested: false,
    //     },
    //     {
    //         title: "Мэдээ мэдээлэл",
    //         path: "/news",
    //         nested: false,
    //     },
    //     {
    //         title: "Холбоо барих",
    //         path: "/contact",
    //         nested: false,
    //     },
    // ];

    return (
        <div className='flex flex-col w-screen'>
            <div className='w-screen flex bg-[#DC3545] text-white transition duration-500 py-4 px-4 items-center justify-between'>
                <span className='text-white font-semibold'>Цэвэр хот</span>
                <div className='transition-all duration-500'>
                    {showMenu ? (
                        <BiX
                            size={30}
                            className='cursor-pointer'
                            onClick={handleClick}
                        />

                    ) : (<BiMenu
                        size={30}
                        className='cursor-pointer'
                        onClick={handleClick}
                    />
                    )}
                </div>
            </div>
            <div
                className={`w-full flex flex-col transition-all duration-500 ${showMenu ? 'h-auto' : 'h-0 overflow-hidden'}`}
            >
                {links.map((list, index) => (
                    <div key={index} className='w-full'>
                        {list.nested ? (
                            <div className='flex flex-col'>
                                <div
                                    onClick={() => handleCategoryClick(index)}
                                    className='flex w-full items-center justify-between px-4 py-3 border-b-2'
                                >
                                    {list.title}
                                    <BiPlus />
                                </div>
                                {selectedIndex === index && (
                                    <div className='flex flex-col'>
                                        {list.nested.map((nestedLink, nestedIndex) => (
                                            <div>
                                                {nestedLink.mininested ? <button
                                                    key={nestedIndex}
                                                    onClick={() => {
                                                        nestedIndex === id ? setSelectedId('') :
                                                            setSelectedId(nestedIndex);
                                                    }}
                                                    className='w-full flex flex-col px-8 bg-[#d3d3d3] py-3 align-start justify-start'
                                                >
                                                    <p className='border-b-2 w-full text-start flex justify-between'>
                                                        {nestedLink.title} {id} <span>{nestedIndex === id ? '-' : '+'}</span>
                                                    </p>
                                                    {nestedIndex === id && nestedLink.mininested &&
                                                        <div className="w-full text-start pl-5 pt-3 flex flex-col align-start justify-start gap-5">
                                                            {nestedLink.mininested.map((el, index) => {
                                                                return (
                                                                    <a key={index} className='border-b border-[#838383]' to={el?.path}>
                                                                        <span>{el.title}</span>
                                                                    </a>
                                                                );
                                                            })}
                                                        </div>}
                                                </button> :
                                                    <Link
                                                        key={nestedIndex}
                                                        to={nestedLink.path}
                                                        className='w-full flex px-8 bg-[#d3d3d3]  py-3 border-b-2'
                                                    >
                                                        {nestedLink.title}
                                                    </Link>}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Link
                                to={list.path}
                                className='w-full flex px-4 py-3 border-b-2'
                            >
                                {list.title}
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MobileNavbar;
