import React, { useState } from 'react'
import styles from './Services.module.css'
import img1 from '../../../Assets/how1.png'
import img2 from '../../../Assets/how2.webp'
import img3 from '../../../Assets/how3.png'
import bg from '../../../Assets/service_bg.jpeg'
import optionbg from '../../../Assets/service_option_bg.jpeg'
import { Link } from 'react-router-dom'

const Services = () => {
    const [index, setIndex] = useState(0);

    return (
        <div className={styles.container} style={{ backgroundImage: `url(${bg})` }}>
            <div className={styles.contents}>
                <div className={styles.titles}>
                    <p className='title' style={{ fontSize: '18px' }}>
                        Үйлчилгээ
                    </p>
                    <p className='big-title' style={{ width: window.innerWidth > 640 ? '35vw' : '80vw' }}>
                        We Provide Best <span style={{ color: '#cd0d13' }}>Services </span>
                        For any Plumbing
                    </p>
                </div>
                <div className={styles.content}>
                    <div className={styles.options}>
                        {index !== 0 ?
                            <p onClick={() => setIndex(0)} className={styles.unactive} style={{ backgroundImage: `url(${optionbg})` }} >Тээвэрлэлт</p>
                            : <div>
                                <p className={styles.text}>Тээвэрлэлт</p>
                                <p className={styles.active} style={{ backgroundImage: `url(${optionbg})` }} ></p>
                            </div>
                        }
                        {index !== 1 ?
                            <p onClick={() => setIndex(1)} className={styles.unactive} style={{ backgroundImage: `url(${optionbg})` }} >Орц цэвэрлэгээ</p>
                            : <div>
                                <p className={styles.text}>Орц цэвэрлэгээ</p>
                                <p className={styles.active} style={{ backgroundImage: `url(${optionbg})` }} ></p>
                            </div>
                        }
                        {index !== 2 ?
                            <p onClick={() => setIndex(2)} className={styles.unactive} style={{ backgroundImage: `url(${optionbg})` }} >Хогийн цэг</p>
                            : <div>
                                <p className={styles.text}>Хогийн цэг</p>
                                <p className={styles.active} style={{ backgroundImage: `url(${optionbg})` }} ></p>
                            </div>
                        }
                    </div>
                    <div className="column" style={{ justifyContent: 'end' }}>
                        <div className={styles.desc}>
                            <div className={styles.desc_img}>
                                <img src={index === 0 ? img1 : index === 1 ? img2 : img3} alt="" />
                            </div>
                            <div className={styles.desc_content}>
                                <p className="big-title">
                                    {index === 0 ? 'Хог хаягдлын тээвэрлэл' : index === 1 ? 'Орон сууцны орц цэвэр' : 'Нэгдсэн хогийн цэг'}
                                </p>
                                <p className='text'>
                                    {index === 0 ? 'Иргэд оршин суугчдад амьдрахад таатай, байгаль орчинд ээлтэй хог хаягдалгүй ногоон орчинг бий болгоно.'
                                        : index === 1 ? 'Баянхонгор аймагийн хог хаягдлыг төвлөрсөн журмаар ачиж тээвэрлэн нэгдсэн хогийн цэгт хүргэх, хүрээлэн буй орчинд үзүүл...'
                                            : 'Хог хаягдлыг эдийн засгийн эргэлтэд оруулах. Ил задгай хог хаягдалгүй байж цэвэр орчинг бүрдүүлэх.'}
                                </p>
                            </div>
                        </div>
                        <div>
                            <Link to={'/main/service1'} className={styles.button}>Дэлгэрэнгүй</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
