import React from 'react'
import styles from './News.module.css'
import { FaUser } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaComments } from "react-icons/fa6";
import dayjs from 'dayjs';

const NewsDetialCard = ({ news, categories }) => {
    return (
        <div className={styles.detialcard}>
            <div className={styles.detialcard_item}>
                <div className={styles.info}>
                    <p> <FaUser color='#cd0d13' size={18} /> by tsever hot</p>
                    <p> <MdOutlineCalendarMonth color='#cd0d13' size={22} /> {dayjs(news.createdAt).format('YYYY-MM-DD')}</p>
                    <p> <FaComments color='#cd0d13' size={22} /> 0 Comments</p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: news.content }} />
                {
                    news.videos ?
                        news.videos.map((e, index) => (
                            <div key={index} style={{ marginBottom: '30px' }} className='video-container'>
                                <video
                                    src={'https://tseverhot.templateapi.xyz/uploads/' + e.video}
                                    controls
                                    width="100%"
                                    height="auto"
                                >
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        ))
                        : null
                }
            </div>
            <div className={styles.category}>
                <h2 className={styles.title} style={{ fontSize: '16px', fontWeight: 550 }}>Categories:</h2>
                <p className={styles.category_name}>{categories.find((e) => e._id === news.category)?.title}</p>
            </div>
        </div>
    )
}

export default NewsDetialCard
