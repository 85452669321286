import React from 'react'
import Cover from '../../../Uttils/Cover/Cover'
import NewsComponent from '../../../Components/News/News'
import { useParams } from 'react-router-dom';

const NewsDetail = ({ news, categories }) => {
    const { name } = useParams();

    return (
        <div>
            <div>
                <Cover title={news.find((e) => e._id === name)?.title} />
                <NewsComponent news={news} isDetial={true} id={name} categories={categories} />
            </div>
        </div>
    )
}

export default NewsDetail
