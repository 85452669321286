import React from 'react'
import styles from './Service_2_header.module.css'
import bg from '../../Assets/swept_icon.png'

const ServiceTwoHeader = () => {
    return (
        <div style={{ backgroundImage: `url(${bg})` }} className={styles.container}>
            <div className={styles.title}>
                <div className="divider" style={{ width: '30vw', borderColor: '#dedfe2' }}></div>
                <p>Үйлчилгээ</p>
                <div className="divider" style={{ width: '30vw', borderColor: '#dedfe2' }}></div>
            </div>
            <h2 className={styles.text}>
                Орон сууцны <p> Орц </p> Цэвэрлэгээ
            </h2>
        </div>
    )
}

export default ServiceTwoHeader
