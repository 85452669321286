import React, { useState } from 'react'
import styles from './AboutUs.module.css';
import { IoIosAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import img from '../../../Assets/slider2.jpeg'
import Cover from '../../../Uttils/Cover/Cover';
import cardimg from '../../../Assets/service_option_bg.jpeg'
import icon1 from '../../../Assets/aboutus/icon1.png'
import icon2 from '../../../Assets/aboutus/icon2.png'
import icon3 from '../../../Assets/aboutus/icon3.png'
import table1 from '../../../Assets/aboutus/table1.png'
import table2 from '../../../Assets/aboutus/table2.png'
import table31 from '../../../Assets/aboutus/table3.1.png'
import table32 from '../../../Assets/aboutus/table3.2.png'
import table41 from '../../../Assets/aboutus/table4.1.png'
import table42 from '../../../Assets/aboutus/table4.2.png'
import table43 from '../../../Assets/aboutus/table4.3.png'
import table51 from '../../../Assets/aboutus/table5.1.png'
import table52 from '../../../Assets/aboutus/table5.2.png'
import table53 from '../../../Assets/aboutus/table5.3.png'
import table61 from '../../../Assets/aboutus/table6,1.png'
import table62 from '../../../Assets/aboutus/table6.2.png'
import table63 from '../../../Assets/aboutus/table6.3.png'
import table7 from '../../../Assets/aboutus/table7.png'

const AboutUs = () => {
    const [index, setIndex] = useState(1);

    return (
        <div className={styles.container}>
            <Cover title={'Бидний Тухай - Tseverhot2.Fununity.Net'} path1={'HOME'} path2={'БИДНИЙ ТУХАЙ'} />
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.bg}></div>
                    <img src={icon1} className={styles.icon} alt="" />
                    <img className={styles.img} src={cardimg} alt="" />
                    <div style={{ color: '#0c2462' }} className="title">Алсын хараа</div>
                    <p>Иргэд оршин суугчдад амьдрахад таатай, байгаль орчинд
                        ээлтэй хог хаягдалгүй ногоон цэвэр орчинг бий болгоно.</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.bg}></div>
                    <img src={icon2} className={styles.icon} alt="" />
                    <img className={styles.img} src={cardimg} alt="" />
                    <div style={{ color: '#0c2462' }} className="title">Эрхэм зорилго</div>
                    <p>Баянхонгор аймгийн хог хаягдлыг төвлөрсөн журмаар ачиж тээвэрлэн нэгдсэн хогийн цэгт хүргэх, хүрээлэн буй орчинд үзүүлэх сөрөг нөлөөллийг бууруулах, хог хаягдлыг дахин ашиглах, эдийн засгийн эргэлтэд оруулахтай холбоотой иргэд, аж ахуйн нэгж байгууллагын нийтийн хариуцлагыг нэмэгдүүлэх.</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.bg}></div>
                    <img src={icon3} className={styles.icon} alt="" />
                    <img className={styles.img} src={cardimg} alt="" />
                    <div style={{ color: '#0c2462' }} className="title">Тэргүүлэх чиглэл</div>
                    <p>Хог хаягдлыг эдийн засгийн эргэлтэд оруулах. Ил задгай хог хаягдалгүй байж цэвэр орчинг бүрдүүлэх.</p>
                </div>
            </div>
            <div className={styles.askcontainer}>
                <div className={styles.titles}>
                    <img src={img} alt="" />
                </div>
                <div className={styles.asks}>
                    <div className={styles.ask}>
                        <div className={styles.ask_title}
                            onClick={() => {
                                index !== 1 ? setIndex(1) : setIndex(0)
                            }}
                        >
                            <div className={index !== 1 ? styles.ask_ask : styles.ask_ask_click}>
                                <p>
                                    Түүхэн замнал
                                </p>
                                {
                                    index !== 1 ? <IoIosAdd
                                        size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(1)} />
                                        : <FiMinus color='red'
                                            size={window.innerWidth > 700 ? 20 : 25}
                                            onClick={() => setIndex(0)} />
                                }
                            </div>
                            {
                                index === 1 && <div className={styles.answer}>
                                    <p>
                                        Хог хаягдал тээвэрлэх үйлчилгээ нь аймаг байгуулагдсан цагаас эхэлсэн бөгөөд нийтийн ахуйн үйлчилгээний газарт харъяалагдан хог тээвэрлэлтийн ажлыг гүйцэтгэж байгаад Чандмань компанид хог тээврийн алба болон үйл ажиллагаагаа явуулж байсан. 2010 оны 5 дугаар сард Баянхонгор аймгийн ИТХ-ын шийдвэрээр Хот тохижилт үйлчилгээний компанид харъяалагдан орж, Сумын Иргэдийн төлөөлөгчдийн хурлын 2014 оны 04 дүгээр сарын
                                        28-ны өдрийн 7/6 дугаар тогтоолоор орон нутгийн өмчит хуулийн этгээдийг өөрчлөн байгуулах
                                        шийдвэрийг гаргаж үйл ажиллагаагаар нь салгасан. Энэ тогтоолын хүрээнд Хот тохижилт ОНӨҮГазрыг
                                        үйл ажиллагааны чиглэлээр нь Хот тохижилт ОНӨҮГазар, Хог тээвэрлэлт ОНӨҮГазар болгон өөрчлөн байгуулж
                                        баталсан бөгөөд Хот тохижилт нь улсын төсөвтэй, Хог тээвэрлэлт нь улсын төсөвгүй өөрийн орлого бүрдүүлэлтээр аж ахуйн тооцоон дээрээ үйл ажиллагаагаа бие даан явуулахаар болж 30 орон тоотой, 9 техник хэрэгсэлтэйгээр, жилдээ 126,641,849 мянган төгрөгийн орлоготой, 112,707,637 төгрөгийн зардалтай ажиллаж эхэлсэн түүхтэй. 2014-2016 онуудад Хог тээвэрлэлт ОНӨҮГазар нэртэйгээр бие даасан үйл ажиллагаа явуулж байгаад, Баянхонгор аймгийн ИТХ-ын 2016 оны 09 дүгээр сарын 28-ний өдрийн 85 дугаар тогтоолоор Цэвэр хот ОНӨ ХХКомпани, 2019 оны 12 дугаар сарын 25-ны өдрийн 134 дүгээр тогтоолоор Цэвэр хот ОНӨ ААТҮГазар болон өмчийн хэлбэрээ өөрчлүүлэн аймгийн хэмжээнд хог хаягдал тээвэрлэлт, нохой муур устгал, орон сууцны орцны цэвэрлэгээ үйлчилгээг хариуцан 3 хэсэгтэй 60 орон тоотой, 18 техник хэрэгсэлтэйгээр өргөжин тэлж Баянхонгор сумын 10 багийн айл өрх, аж ахуй нэгж байгууллагын хог хаягдлыг ачиж тээвэрлэн өнөөдрийг хүртэл идэвхитэй үйл ажиллагаа явуулж байна.
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.ask}
                        onClick={() => {
                            index !== 2 ? setIndex(2) : setIndex(0)
                        }}
                    >
                        <div className={styles.ask_title}>
                            <div className={index !== 2 ? styles.ask_ask : styles.ask_ask_click}>
                                <p>
                                    Үе үеийн удирдлагууд
                                </p>
                                {
                                    index !== 2 ? <IoIosAdd
                                        size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(2)} />
                                        : <FiMinus color='red'
                                            size={window.innerWidth > 700 ? 20 : 25}
                                            onClick={() => setIndex(0)} />
                                }
                            </div>
                            {index === 2 && <li className={styles.answer}>
                                <li>2014.06.09-11.07-ны өдрийг хүртэл Бямбадоржийн Ганболд.</li>
                                <li>2014.11.07-2015.01.01-ны өдрийг хүртэл Д.Рэнцэнцэдэв.</li>
                                <li>2015.01.02-05.12-ны өдрийг хүртэл Дашзэвэгийн Баасанжав.</li>
                                <li>2015.05.12-2016.10.03-ны өдрийг хүртэл Дашдондогийн Ганбат.</li>
                                <li>2016.10.03-ны өдрөөс эхлэн аймгийн засаг даргын Б/605 дугаартай захирамжаар Цагаандоржийн Баясгаланг томилон ажиллуулсан байна.</li>
                            </li>}
                        </div>
                    </div>
                    <div className={styles.ask}
                        onClick={() => {
                            index !== 3 ? setIndex(3) : setIndex(0)
                        }}
                    >
                        <div className={styles.ask_title}>
                            <div className={index !== 3 ? styles.ask_ask : styles.ask_ask_click}>
                                <p>
                                    Манай багт хог ачиж байгаа жолоочийн дугаар хэд вэ?
                                </p>
                                {
                                    index !== 3 ? <IoIosAdd size={window.innerWidth > 700 ? 20 : 25}
                                        onClick={() => setIndex(3)} />
                                        : <FiMinus color='red'
                                            size={window.innerWidth > 700 ? 20 : 25}
                                            onClick={() => setIndex(0)} />
                                }
                            </div>
                            {index === 3 && <div className={styles.answer}>
                                <p>
                                    Бие даасан үйл ажиллагаа явуулж эхэлснээс хойш дараах өөрчлөлт шинэчлэлтүүд хийгдсэн. Үүнд: <br /> <br />
                                    • Цэвэр хот орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газар болж үйл ажиллагааны дүрэм журмаа шинэчлүүлсэн<br /> <br />
                                    • Байгууллагын авто машинуудыг нэгдсэн зохион байгуулалттай паркжуулалтад оруулж, төвлөрсөн нэг газарт байрлуулан авто тээврийн хэрэгслүүдийн эвдрэл гэмтэл, ажлын цагийн ашиглалтыг сайжруулж, шатахууны олголтод хяналт тавин үргүй зардлыг багасгаж эхэлсэн. <br /> <br />
                                    • Тус газраас орон сууцны цэвэрлэгээ үйлчилгээ авдаг 58 байрны 1027 өрхийг 7 үйлчлэгч цэвэрлэдэг байсан бол 100 байрны 2581 өрх болгон нэмэгдүүлж 12 үйлчлэгчийг ажиллуулж орон сууцны орцны цэвэрлэгээ, үйлчилгээний үнэ тарифийг Баянхонгор аймгийн ИТХ-ын 2019 оны 24 дүгээр тогтоолоор батлуулж эрчим хүчний хэрэглээний төлбөр дээр хамтатган бодуулах шийдвэрийг гаргуулан өнөөдрийг хүртэл хэрэгжүүлэн ажиллаж ирсэн. <br /> <br />
                                    • Авто техникийн хүрэлцээ хангамж, шинэчлэлтийг сайжруулан аймаг, сумын Засаг дарга, ИТХ-д удаа дараа асуудал хэлэлцүүлж 2017 онд хово автомашин-1, 2019 онд японы кантер шахагч машин-2, албаны суудлын зориулалттай автомашин-1, 2021 онд 25тн-ийн даацтай норд автомашин-2, дугуйт экскеватор-1 хүнд даацын авто машинуудыг орон нутгийн хөгжлийн сангийн хөрөнгөөр шийдвэрлүүлж ажилласан. <br /> <br />
                                    • Баянхонгор сумын төвлөрсөн шинэ хогийн цэгийг шинэ суурьшлийн бүсээс 13 км зайд Өлзийт сумын 1 дүгээр багийн нутаг дэвсгэрт 70 метрийн өргөнтэй, 100 метрийн урттай, 4 метрийн гүнтэй, 4 метрийн далан хийж нийт 8 метрийн гүнтэй шинэ хогийн цэгийг барихад орон нутгийн хөгжлийн сангаас 200.0 сая төгрөгийн санхүүгийн асуудлыг шийдвэрлэж “Талын нөмөр” ХХК-иар гүйцэтгүүлэн шинээр байгуулж 2023 оны 05 дугаар сараас эхлэн 26686 тн хог хаягдлыг хүлээн авсан байна. <br /> <br />
                                    • Байгууллага өөрийн гэсэн газар, граш албаны өрөө байхгүй байсныг аймаг орон нутгийн удирдлагуудтай ярилцаж 10 машины зогсоолтой автогараашийг 2017 оны 12 дугаар сард хүлээн авч байсан. <br /> <br />
                                    • Хог хаягдал тээвэрлэлтийг тогтмол цаг хугацаанд гүйцэтгэж 2019 онд 22564 тн, 2020 онд 28360, 2021 онд 36039 тн хог, 2022 онд 34488 тн хог, 2023 онд 30426,3 тн хаягдлыг тээвэрлэж төвлөрсөн хогийн цэгт хүлээн авсан. <br /> <br />
                                    • Баянхонгор сумын нутаг дэвсгэрийн хэмжээнд 2023 оны 1 дүгээр сараас эхлэн хог хаягдлыг ангилан ялгах ажлыг зохион байгуулж тус ондоо үнс 2844 тн, хуванцар 14,04тн-ийг ангилан ялгаж хог хаягдлыг 21%-иар бууруулан ажилласан. <br /> <br />
                                    • Тогтвортой хөгжлийн боловсролыг түгээн дэлгэрүүлэх зорилгоор “Эко Баянхонгор” төслийн зөвлөхөөр ажиллаж тогтвортой хөгжлийн боловсролыг бүх нийтээр эзэмшүүлье уриалгад нэгдэн аймгийн БОАЖГазар, Нэгдсэн эмнэлэг, МХГазар, БСУГазар, Насан туршийн боловсролын төв болон хэвлэл мэдээллийн байгууллагуудтай хамтран “Тогтвортой хөгжил ба Хог хаягдлын менежмент“ сургалтыг хамтран зохион байгуулж жилд дундажаар 1500-1700 гаруй төрийн албан хаагч иргэдийг хамруулан сургалт мэдээлэл хийж ажилласан. <br /> <br />
                                    • Аймгийн хэмжээний нохой муур устгалын ажлыг хийхдээ сумын Засаг даргын захирамж гаргуулан Цагдаагийн газар, Багийн засаг дарга нартай хамтран гэрээний дагуу зөрчил дутагдал гаргалгүй зохион байгуулан 2020 онд 627 нохой 32 муур, 2021 онд 768 нохой 12 муурыг, 2022 онд 972 нохой 65 муур, 2023 онд 984 нохой, 240 гөлөг, 26 муурыг буудлагын аргаар устгаж иргэдэд аюулгүй орчинг бүрдүүлж ажилласан. <br /> <br />
                                    • Байгууллагын төсөв санхүүгийн асуудал хүндрэлтэй байсныг 2023 онд багуудын ИНХ-аар санал оруулж хэлэлцүүлэн сумын ИТХ-ын 2023 оны 12 дугаар сарын 18-ны өдрийн 12/73 дугаар тогтоолоор хог хаягдлын үйлчилгээний төлбөр хураамжийг 8000-110,000 мянган төгрөг болгон орлогыг 90 %-иар өсгөн нэмэгдүүлсэн.• Хөдөлмөрийн хүнд нөхцөлд ажиллаж байгаа ажилтан албан хаагчдын цалинг 70-120 %-иар өсгөж нэмэгдүүлсэн зэрэг олон ажлуудыг санаачилж төрлөх хотоо хоггүй цэвэр, иргэддээ ээлтэй хот болгохын төлөө байгууллагын ажилтан албан хаагчид хичээл зүтгэл гарган ажиллаж байна. <br />
                                </p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='column'>
                <h2 className="big-title">Бүтэц орон тоо</h2>
                <img src={table1} alt="" />
            </div>
            <br />
            <br />
            <div className='column'>
                <h2 style={{ width: '60vw', fontSize: '35px', textAlign: 'center' }}
                    className="big-title">Цэвэр хот орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газрын 2024 оны гүйцэтгэлийн төвөлөгөө</h2>
                <p style={{ paddingLeft: '20vw', fontWeight: 'bold', alignSelf: 'start' }}>Нэг. Гүйцэтгэлийн зорилт арга хэмжээ</p>
                <br />
                <img src={table2} alt="" />
                <br />
                <br />
                <div>
                    <p style={{ fontWeight: 'bold', alignSelf: 'start' }}>Хоёр. Хуулиар тусгайлан олгосон чиг үүргийг хэрэгжүүлэх зорилт</p>
                    <div className="grid-2">
                        <img style={{ width: '30vw' }} src={table31} alt="" />
                        <img style={{ width: '30vw' }} src={table32} alt="" />
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <p style={{ fontWeight: 'bold', alignSelf: 'start' }}>Гурав. Хуулиар олгосон нийтлэг чиг үүргийг хэрэгжүүлэх зорилт</p>
                    <div className="grid-2">
                        <img style={{ width: '30vw' }} src={table41} alt="" />
                        <img style={{ width: '30vw' }} src={table42} alt="" />
                    </div>
                </div>
                <img src={table43} alt="" />
            </div>
            <br />
            <br />
            <div className='column'>
                <h2 style={{ width: '60vw', fontSize: '35px', textAlign: 'center' }}
                    className="big-title">Цэвэр хот орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газарт хамааралтай хууль тогтоомж, тогтоол шийдвэрийн жилийн хэрэгжилт</h2>
                <div>
                    <p style={{ fontWeight: 'bold', alignSelf: 'start' }}>2024 оны 03 дугаар сарын 03 ны өдөр Рашаант</p>
                    <div className="grid-2">
                        <img style={{ width: '30vw' }} src={table51} alt="" />
                        <img style={{ width: '30vw' }} src={table52} alt="" />
                    </div>
                </div>
                <img src={table53} alt="" />
            </div>
            <div className='column'>
                <h2 style={{ width: '60vw', fontSize: '35px', textAlign: 'center' }}
                    className="big-title">Баянхонгор аймгийн Цэвэр хот ОНӨ ААТҮГазарт хамааралтай сургалт ажиллах нөхцөл, нийгмийн баталгааг хангах хөтөлбөрийн 2023 оны жилийн хэрэгжилт</h2>
                <div>
                    <div className="grid-2">
                        <img style={{ width: '30vw' }} src={table61} alt="" />
                        <img style={{ width: '30vw' }} src={table62} alt="" />
                    </div>
                </div>
                <img src={table63} alt="" />
            </div>
            <br /><br />
            <div className='column'>
                <h2 style={{ width: '60vw', fontSize: '35px', textAlign: 'center' }}
                    className="big-title">Цэвэр хот орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газраас сахилгын шийтгэл хүлээсэн төрийн үйлчилгээний албан хаагчийн судалгаа 2023 оны байдлаар</h2>
                <img src={table7} alt="" />
            </div>
            <div className='row' style={{ padding: '20px' }}>
                <div style={{ color: '#0c2462', fontSize: '17px', fontWeight: 'bold', width: '25vw', textAlign: 'center' }} className="title">"Цэвэр хот" орон нутгийн өмчит аж ахуйн тооцоотой үйлдвэрийн газрын дүрэм</div>
                <div>
                    <a href='https://drive.google.com/file/d/1v6WSte8fPb_GHtBFyVpjTQb1LRHskF_g/view' className='button'>Дэлгэрэнгүй</a>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
