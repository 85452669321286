import React from 'react'
import styles from './HowDoYouDo.module.css'
import how1 from '../../../Assets/how1.png'
import how2 from '../../../Assets/how2.webp'
import how3 from '../../../Assets/how3.png'
import arrow from '../../../Assets/arrow.png'

const HowDoYouDo = () => {
    return (
        <div className={styles.container}>
            <div className={styles.titles}>
                <p className={styles.title}>БИДНИЙ ҮЙЛЧИЛГЭЭ</p>
                <p className={styles.text}>Бид хэрхэн ажилладаг вэ?</p>
            </div>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.img_div}></div>
                    <img src={how1} alt="" />
                    <p className={styles.number}>1</p>
                    <p className={styles.card_title}>Хог хаягдал тээвэрлэлт</p>
                    <p className={styles.card_text}>Өдөр бүр байгальд хайртай иргэн байгаарай.</p>
                </div>
                {window.innerWidth > 600 ? <img src={arrow} className={styles.arrow} alt="" /> : null}
                <div className={styles.card}>
                    <div className={styles.img_div}></div>
                    <img src={how2} alt="" />
                    <p className={styles.number}>2</p>
                    <p className={styles.card_title}>Орон сууцны oрцны цэвэрлэгээ үйлчилгээ</p>
                    <p className={styles.card_text}>Хог хаягдлаа ил задгай бүү хаяаарай.</p>
                </div>
                {window.innerWidth > 600 ? <img src={arrow} className={styles.arrow} alt="" /> : null}
                <div className={styles.card}>
                    <div className={styles.img_div}></div>
                    <img src={how3} alt="" />
                    <p className={styles.number}>3</p>
                    <p className={styles.card_title}>Нэгдсэн хогийн цэг</p>
                    <p className={styles.card_text}>Баянхонгор аймаг. Цэвэр хот ОНӨ</p>
                </div>
            </div>
        </div>
    )
}

export default HowDoYouDo
