import React from 'react'
import styles from './News.module.css'
import { FaUser } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const NewsCard = ({ data }) => {
    return (
        <div className={styles.card}>
            <div className={styles.info}>
                <p> <FaUser color='#cd0d13' size={18} /> by tsever hot</p>
                <p> <MdOutlineCalendarMonth color='#cd0d13' size={22} /> {dayjs(data.createdAt).format('YYYY-MM-DD')}</p>
            </div>
            <h2 className={styles.title}>{data.title}</h2>
            <p className={styles.text}>{data.minititle}</p>
            <Link to={'/newsdetial/' + data._id} className={styles.button}>Дэлгэрэнгүй унших</Link>
        </div>
    )
}

export default NewsCard
