import React from 'react'
import styles from './Control.module.css'
import img1 from '../../../Assets/control_1.png'
import img2 from '../../../Assets/control_2.png'
import img3 from '../../../Assets/control_3.png'
import img4 from '../../../Assets/control_4.png'

const ControlPage = () => {
    return (
        <div className={styles.container}>
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
        </div>
    )
}

export default ControlPage
