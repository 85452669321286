import React from "react";
import "./Service1.css";
import Hero from "../../Components/Service/Hero";
import Intro from "../../Components/Service/Intro";
import Excel from "../../Components/Service/Excel";
import Cover from "../../Uttils/Cover/Cover";
import img from '../../Assets/service_1.jpeg'
import Service1Driver from "../../Components/Service/Service1/Service1Driver";
import Service1FeedBack from "../../Components/Service/Service1/Service1FeedBack";

function Service1() {
  return (
    <div
      // className="w-full flex flex-col items-center"
      style={{ position: "relative" }}
    >
      {/* <Hero text={"Хог Хаягдалт Тээвэрлэлт - Tseverhot2.Fununity.Net"} /> */}
      <Cover title={"Хог Хаягдалт Тээвэрлэлт - Tseverhot2.Fununity.Net"} />

      <div className="container">
        <br />
        <Intro which={1} img={img} />
        <Excel which={1} />
        <Service1Driver />
        <Service1FeedBack />
      </div>
    </div>
  );
}

export default Service1;
