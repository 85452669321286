import React from "react";
import "./Service1.css";
import Intro from "../../Components/Service/Intro";
import Excel from "../../Components/Service/Excel";
import ServiceList from "../../Components/Service/ServiceList";
import Cover from "../../Uttils/Cover/Cover";
import img from '../../Assets/service_2.jpeg'
import ServiceTwoHeader from "../../Components/Service/Service_2_header";

function Service2() {
  return (
    <div
      // className="w-full flex flex-col items-center"
      style={{ position: "relative" }}
    >
      <Cover title={"Хог Хаягдалт Тээвэрлэлт - Tseverhot2.Fununity.Net"} path1={'HOME'} path2={'ХОГ ХАЯГДАЛ ТЭЭВЭРЛЭЛТ'} />

      <div className="container">
        <ServiceTwoHeader />
        <Intro which={2} img={img} />
        <Excel />
      </div>
    </div>
  );
}

export default Service2;
