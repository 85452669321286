import React, { useState } from 'react'
import styles from './News.module.css'
import NewsCard from './NewsCard';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import NewsDetialCard from './NewsDetialCard';

const NewsComponent = ({ isDetial, news, id, categories }) => {
    const [date, setDate] = useState(dayjs());

    return (
        <div>
            <div className={styles.container}>
                {!isDetial ?
                    <div className={styles.contents}>
                        {news.map((e) => (
                            <NewsCard data={e} />
                        ))}
                    </div> :
                    <div className={styles.contents}>
                        <NewsDetialCard news={news.find((e) => e._id === id)} categories={categories} />
                    </div>
                }
                <div className={styles.sidebar}>
                    <div className={styles.sidebar_item}>
                        <h2 className={styles.title} style={{ fontSize: '23px', fontWeight: 600 }}>Сүүлийн үеийн мэдээ</h2>
                        <div className={styles.last_news}>
                            <div className={styles.last_news_card}>
                                <p className={styles.link}>Нээлттэй ажлын байр</p>
                                <p>by tseverhot2.fununity.net</p>
                                <p>29 April 2024</p>
                                <div className="divider" style={{ width: '25vw', borderColor: '#e8e8e8', borderWidth: '0.2px' }}></div>
                            </div>
                            <div className={styles.last_news_card}>
                                <p className={styles.link}>Нээлттэй ажлын байр</p>
                                <p>by tseverhot2.fununity.net</p>
                                <p>29 April 2024</p>
                                <div className="divider" style={{ width: '25vw', borderColor: '#e8e8e8', borderWidth: '0.2px' }}></div>
                            </div>
                            <div className={styles.last_news_card}>
                                <p className={styles.link}>Нээлттэй ажлын байр</p>
                                <p>by tseverhot2.fununity.net</p>
                                <p>29 April 2024</p>
                                <div className="divider" style={{ width: '25vw', borderColor: '#e8e8e8', borderWidth: '0.2px' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sidebar_item}>
                        <h2 className={styles.title} style={{ fontSize: '23px', fontWeight: 600 }}>Категори</h2>
                        <select name="" id="">
                            <option value="">Сонгох</option>
                            <option value="">Мэдээ</option>
                        </select>
                    </div>
                    <div className={styles.sidebar_item}>
                        <Calendar
                            value={date.toDate()}
                            onChange={(e) => setDate(dayjs(e))}
                            className={styles.calendar}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsComponent
