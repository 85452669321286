import React from 'react'
import styles from '../Unit.module.css'
import img1 from '../../../Assets/person1.jpeg'
import img2 from '../../../Assets/person2.jpeg'
import img3 from '../../../Assets/person3.jpeg'

const WorkSearchPage = () => {
    return (
        <div className={styles.container}>
            <div>
                {/* <div className="title">ТУЗ дүрэм:</div> */}
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
            </div>
        </div>
    )
}

export default WorkSearchPage
