import React from 'react'
import styles from './Cover.module.css';
import { FaChevronRight } from "react-icons/fa6";
import img from '../../Assets/greeting_cover.jpeg'

const Cover = ({ path1, path2, title }) => {
  return (
    <div style={{ backgroundImage: `url(${img})` }} className={styles.cover}>
      <h2 style={{ fontSize: window.innerWidth > 600 ? '45px' : '25px', fontWeight: 'bold' }}>
        {title}
      </h2>
      <p>{path1} <FaChevronRight /> {path2}
      </p>
    </div>
  )
}

export default Cover
